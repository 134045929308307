import React from 'react';
import WebContainer from 'core/ui/atoms/WebContainer';
import PAYMENTS from './payments';

const PaymentsPage = () => {
  return (
    <WebContainer>
      <div dangerouslySetInnerHTML={{ __html: PAYMENTS }}></div>
    </WebContainer>
  );
};

export default PaymentsPage;
