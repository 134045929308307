import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import APPLE_ICON from '../../assets/images/apple_icon.svg';
import PLAYSTORE_ICON from '../../assets/images/playstore_icon.svg';

const GetAppButton = ({ small, isApple, disabled, customText }) => {
  const ua = window.navigator.userAgent;
  const isIos = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const { t } = useTranslation();

  const goTo = () => {
    window.location.href = isApple
      ? 'https://apps.apple.com/ru/app/flero-%D1%81%D0%B0%D0%B9%D1%82-%D0%B4%D0%BB%D1%8F-%D0%B7%D0%BD%D0%B0%D0%BA%D0%BE%D0%BC%D1%81%D1%82%D0%B2/id1479292091'
      : 'https://play.google.com/store/apps/details?id=com.microcosm.microcosm';
  };

  return (
    <Button
      isApple={isApple}
      isIos={isIos}
      onClick={disabled ? null : goTo}
      small={small}
      disabled={disabled}
      className={'initiate-checkout-btn'}
    >
      <Icon source={isApple ? APPLE_ICON : PLAYSTORE_ICON} isApple={isApple} />
      {customText ||
        t(isApple ? 'main.downloadAppstore' : 'main.downloadPlaystore')}
    </Button>
  );
};

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  position: relative;
  background: white;
  border-radius: 20px;
  padding: 18px 24px;
  width: 275px;
  border: 0;
  background: transparent;
  color: black;
  font-size: 14px;
  line-height: 16px;
  font-family: TT Norms Pro, sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-right: 8px;
  border: 1px solid #898989;
  ${(p) =>
    p.disabled &&
    css`
      background: rgba(255, 255, 255, 0.05);
      color: rgba(255, 255, 255, 0.2);
    `}
  ${(p) => p.theme.max('md')`
    display: ${(p) =>
      (p.isIos && !p.isApple) || (!p.isIos && p.isApple)
        ? p.isPayment
          ? 'flex'
          : 'none'
        : 'flex'};
  `}
  &:hover {
    background: white;
    color: black;
    ${(p) =>
      p.disabled &&
      css`
        background: rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.2);
      `}
    ${(p) =>
      p.isApple &&
      css`
        > div {
          filter: invert(0%);
        }
      `}
  }
  ${(p) =>
    p.small &&
    css`
      font-size: 16px;
      width: 235px;
      padding: 8px 24px 8px 38px;
      > div {
        width: 18px;
        height: 18px;
      }
    `}
  ${(p) => p.theme.max('md')`
    background: white;
    color: black;
    ${(p) =>
      p.disabled &&
      css`
        background: rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.2);
      `}
    ${(p) =>
      p.isApple &&
      css`
        > div {
          filter: invert(0%);
        }
      `}
  `}
`;

const Icon = styled.div`
  /*   position: absolute;
  top: 50%;
  left: ${(p) => (p.isApple ? 24 : 20)}px;
  transform: translate(0, ${(p) => (p.isApple ? '-55%' : '-50%')}); */
  width: ${(p) => (p.isApple ? 18 : 20)}px;
  height: ${(p) => (p.isApple ? 24 : 20)}px;
  background-image: url(${(p) => p.source});
  background-size: cover;
`;

export default GetAppButton;
