import React from 'react';
// import styled from 'styled-components';
// import { Flex, Text, Image, SvgIcon } from 'core/ui/atoms';

const NotFound = () => {
  return <p>Not found</p>;
  // return (
  //   <NotFoundCont>
  //     {/* <Logo /> */}
  //     <NotFoundTitle>Страница не найдена</NotFoundTitle>
  //     <NotFoundDescription>Возможно вы ввели неправильный адрес или открыли нерабочую ссылку.</NotFoundDescription>
  //   </NotFoundCont>
  // )
};

// const NotFoundCont = styled(Flex)`
//   width: 100%;
//   height: 100vh;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   margin: 0 auto;
// `;

// const Logo = styled(SvgIcon.Page404)`
//   margin-bottom: 32px;
//   ${p => p.theme.between('xs', 'md')`
//     width: 223px;
//     height: 88px;
//   `}
// `;

// const NotFoundTitle = styled.h2`
//   font-size: 24px;
//   line-height: 133%;
//   font-weight: 600;
//   padding: 0;
//   margin: 0 0 16px 0;
//   color: ${p => p.theme.colors.black};
//   text-align: center;
// `;

// const NotFoundDescription = styled(Text)`
//   font-size: 18px;
//   line-height: 133%;
//   font-weight: 500;
//   padding: 0 20px;
//   margin: 0 0 24px 0;
//   text-align: center;
//   color: ${p => p.theme.colors.gray.light};
// `;

export default NotFound;
