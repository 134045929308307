import React from 'react';
import styled from 'styled-components';

import FLERO_LOGO from "../../assets/images/logo.svg"

const Logo = ({ large }) => {
  return large ? (
    <LogoContLarge>
      <HeaderLogoLarge src={FLERO_LOGO} alt={'Flero Logo'} />
    </LogoContLarge>
  ) : (
    <LogoCont>
      <HeaderLogo src={FLERO_LOGO} alt={'Flero Logo'} />
    </LogoCont>
  );
}

const LogoCont = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: 40px;
  ${p => p.theme.max('sm')`
    margin-bottom: 20px;
  `}
`;

const LogoContLarge = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: 40px;
`;

const HeaderLogo = styled.img`
  display: block;
  width: 169px;
  ${(p) => p.theme.between("sm", "xl")`
      width: 11.3vw;
    `}
  ${(p) => p.theme.max("md")`
        width: 180px;
    `}
    ${p => p.theme.max('xs')`
      width: 92px;
    `}
`;

const HeaderLogoLarge = styled.img`
  display: block;
  width: 28vw;
  max-width: 320px;
`;

export default Logo;
