import React from 'react';
import WebContainer from 'core/ui/atoms/WebContainer';
import EULA from './eula';

const EulaPage = () => {
  return (
    <WebContainer>
      <div dangerouslySetInnerHTML={{ __html: EULA }}></div>
    </WebContainer>
  );
};

export default EulaPage;
