import styled, { css } from 'styled-components';

const SectionSecond = styled.h2`
  font-size: 38px;
  font-weight: 500;
  line-height: 100%; /* 38px */
  letter-spacing: -0.38px;
  width: 100%;
  text-align: left;
  vertical-align: top;
  padding: 0;
  margin: 0;
  color: ${(p) => p.theme.colors.black};
  ${(p) =>
    p.center &&
    css`
      text-align: center;
    `}
  ${(p) =>
    p.white &&
    css`
      color: ${(p) => p.theme.colors.white};
    `}
    ${(p) =>
    p.h3 &&
    css`
      font-size: 24px;
    `}
  ${(p) =>
    p.primary &&
    css`
      color: ${p.theme.colors.primary};
    `}
  ${(p) =>
    p.noMargin &&
    css`
      margin-bottom: 0;
    `}
  ${(p) => p.theme.max('md')`
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 20px */
    letter-spacing: -0.2px;
  `} /*   ${(p) => p.theme.max('sm')`
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 22px;
  `} // ${(p) => p.theme.max('sm')`
  //   font-size: 33px;
  //   line-height: 56px;
  //   margin-bottom: 36px;
  // `}
  // ${(p) => p.theme.between('sm', 'md')`
  //   font-size: 49px;
  // `} */
`;

export default SectionSecond;
