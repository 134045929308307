import React from 'react';
import styled from 'styled-components';

import CHECKBOX_EMPTY from 'core/assets/images/checkbox_small_empty.svg';
import CHECKBOX_FULL from 'core/assets/images/checkbox_small_full.svg';

// import Text from './Text';

const Checkbox = ({ checked, label, onChecked, children }) => {
  return (
    <CheckBoxCont onClick={onChecked}>
      <CheckIconWrap>
        <img src={checked ? CHECKBOX_FULL : CHECKBOX_EMPTY} alt={'checkbox'} />
      </CheckIconWrap>
      {children}
      {/* <Text white>{label}</Text> */}
    </CheckBoxCont>
  );
};

const CheckBoxCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 12px;
  ${p => p.theme.max('sm')`
    padding-left: 16px;
    padding-right: 16px;
  `}
`;

const CheckIconWrap = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  border: 2px white solid;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-right: 12px;
`;

export default Checkbox;
