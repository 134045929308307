import React from 'react';

function Apple() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='20'
      fill='none'
      viewBox='0 0 18 20'
    >
      <path
        fill='#000'
        d='M17.144 15.586a10.848 10.848 0 01-1.085 1.933c-.57.807-1.038 1.365-1.398 1.674-.558.509-1.156.77-1.797.784-.46 0-1.014-.13-1.659-.392-.647-.262-1.242-.392-1.786-.392-.571 0-1.183.13-1.837.392-.656.263-1.184.4-1.588.413-.614.026-1.226-.242-1.837-.805-.39-.337-.878-.914-1.462-1.733-.627-.874-1.142-1.888-1.546-3.043C.717 13.169.5 11.96.5 10.79c0-1.34.292-2.496.878-3.465a5.125 5.125 0 011.838-1.842A4.978 4.978 0 015.7 4.788c.488 0 1.127.15 1.922.443.793.295 1.301.444 1.525.444.166 0 .732-.175 1.69-.523.907-.323 1.671-.457 2.298-.404 1.698.135 2.974.799 3.822 1.994-1.518.912-2.27 2.189-2.255 3.827.014 1.276.481 2.338 1.4 3.181.415.392.88.694 1.397.909-.112.322-.23.63-.356.927zM13.249.4c0 1-.368 1.934-1.103 2.799-.887 1.027-1.96 1.621-3.124 1.527A3.086 3.086 0 019 4.347c0-.96.422-1.987 1.17-2.828.375-.425.85-.779 1.427-1.061.576-.278 1.12-.432 1.632-.458.015.134.021.267.021.4z'
      ></path>
    </svg>
  );
}

export default Apple;
