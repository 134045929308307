import React from 'react';
import styled from 'styled-components';
import { Container, Text, Box } from 'core/ui/atoms';

const InAppFooter = () => {
  return (
    <footer style={{ marginTop: 'auto' }}>
      <Container>
        <CustomContainer>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexWrap: 'wrap-reverse',
            }}
          >
            <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Text style={{ margin: 'auto' }}>
                Для обращения в поддержку пишите:&nbsp;
              </Text>{' '}
              <CustomA href='mailto: support@flero.io'>
                support@flero.io
              </CustomA>
            </Box>
          </Box>
        </CustomContainer>
      </Container>
    </footer>
  );
};

const CustomA = styled.a`
  margin: auto;
  display: block;
  color: black;
  text-decoration: none;
  border-bottom: 1px solid black;
`;

const CustomContainer = styled(Container)`
  padding-top: 40px;
  padding-bottom: 40px;
`;

export default InAppFooter;
