import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import backImage from '../../assets/images/chevron-left.svg';

const BackButton = () => {
  return (
    <Link to='/'>
      <ButtonCont href={'/'}>
        <Icon src={backImage} alt='log out' />
      </ButtonCont>
    </Link>
  );
};

const ButtonCont = styled.span`
  position: fixed;
  left: 40px;
  top: 30px;
  width: 62px;
  height: 72px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  border: 1px solid #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background: rgba(255, 255, 255, 0.4);
  }
  ${(p) => p.theme.max('sm')`
    width: 64px;
    height: 64px;
    left: 20px;
    bottom: 40px;
	`}
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
  ${(p) => p.theme.max('sm')`
    width: 24px;
    height: 24px;
	`}
`;

export default BackButton;
