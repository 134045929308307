import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Container, Text, SectionHeading } from '../../atoms';
import Success from '../../../assets/images/icons/success';
import CirclesSoc from '../../../assets/images/circles_soc.webp';
import Iphone from '../../../assets/images/iphone_3.png';

const Analyze = () => {
  const { t } = useTranslation();
  return (
    <CustomContainer>
      <HeaderSeparator>
        <SectionHeading>{t('compatibility.title')}</SectionHeading>
      </HeaderSeparator>
      <StyledFlexBox>
        <StyledBox>
          <TextContainer>
            <IconContainer>
              <Success />
            </IconContainer>
            <Text primary>{t('compatibility.text1')}</Text>
          </TextContainer>
          <TextContainer>
            <IconContainer>
              <Success />
            </IconContainer>
            <Text primary>{t('compatibility.text2')}</Text>
          </TextContainer>
        </StyledBox>
        <Box1>
          <Img src={CirclesSoc} width={327} />
        </Box1>
      </StyledFlexBox>
      <StyledReverseBox>
        <Box2>
          <Img src={Iphone} width={327} />
        </Box2>
        <StyledBox2>
          <TextContainer>
            <IconContainer>
              <Success />
            </IconContainer>
            <Text primary>{t('compatibility.text3')}</Text>
          </TextContainer>
          <TextContainer>
            <IconContainer>
              <Success />
            </IconContainer>
            <Text>{t('compatibility.text4')}</Text>
          </TextContainer>
        </StyledBox2>
      </StyledReverseBox>
      <StyledHr />
    </CustomContainer>
  );
};

const CustomContainer = styled(Container)`
  position: relative;
  ${(p) => p.theme.max('md')`
    top: 0;
    `}
`;
const HeaderSeparator = styled.div`
  padding-bottom: 90px;
  /* width: 800px; */
  ${(p) => p.theme.max('md')`
    /* width: 300px; */
    padding-bottom: 40px;
    margin-top: 60px;
  `}
`;

const TextContainer = styled.div`
  width: 476px;
  display: flex;
  ${(p) => p.theme.max('md')`
    width: 300px`}
`;
const IconContainer = styled.div`
  padding-right: 16px;
`;
const StyledBox = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  padding-bottom: 80px;
  ${(p) => p.theme.max('md')`
    padding-top: 0;`}
`;
const StyledBox2 = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 24px;
  padding-bottom: 80px;
  ${(p) => p.theme.max('md')`
    align-items: start;`}
`;
const StyledFlexBox = styled.div`
  display: grid;
  grid-auto-rows: 50%;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 40px;
  ${(p) => p.theme.max('md')`
    display: block;`}
`;

const StyledHr = styled.hr`
  background-color: #e0e3e7;
  height: 1px;
  border: none;
  margin-top: 40px;
  margin-bottom: 80px;
  ${(p) => p.theme.max('md')`
    margin-bottom: 20px;
    `}
`;

const StyledReverseBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: end;
  ${(p) => p.theme.max('md')`
    display: block;`}
`;

const Box1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  height: 500px;
  position: relative;
  background-color: ${(p) => p.theme.colors.secondaryLanding};
`;

const Box2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  border-radius: 36px;
  height: 500px;
  position: relative;
  background-color: ${(p) => p.theme.colors.secondaryLanding};
`;
const Img = styled.img``;

export default Analyze;
