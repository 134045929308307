import i18n from 'i18next';

export const getLanguage = () => i18n.language.split(' ')[0].toLowerCase();

export const getFullAvatar = avatar => {
  if (!avatar) {
    return null;
  }
  return avatar.includes('http') ? avatar : `https://duk1vb8uv1ahc.cloudfront.net/${avatar}`;
};
