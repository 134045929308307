import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';
import AppleSignin from 'react-apple-signin-auth';
import { useMutation } from '@apollo/client';
import jwt_decode from 'jwt-decode';
import { Helmet } from 'react-helmet';

import { SectionWrap, Logo, Text } from 'core/ui/atoms';
import TokenSignIn from 'core/ui/components/TokenSignIn';
import GetAppButton from 'core/ui/components/GetAppButton';
import MentalId from 'core/ui/components/landing/MentalId/index.js';
import Surrounding from 'core/ui/components/landing/Surrounding';
import Analyze from 'core/ui/components/landing/Analyze';
import Assistent from 'core/ui/components/landing/Assistent';
import ChatInvent from 'core/ui/components/landing/ChatInvent/index.js';
import Audience from 'core/ui/components/landing/Audience';
import GradientPromo from 'core/ui/components/landing/GradientPromo';
import Footer from 'core/ui/components/landing/Footer';
import {
  WEB_SIGN_IN_WITH_GOOGLE,
  WEB_SIGN_IN_WITH_APPLE,
} from 'core/api/apollo/mutations';
import { loginWithToken } from 'core/api/actions';
import { withInAppNotifications } from 'core/api/toast/withInAppNotifications';
import Header from '../components/landing/Header';
import { Modal } from 'core/ui/atoms';
// import useViewport from '../responsiveContext';

import BG_VIDEO from '../../assets/video/bg_video.mp4';

const SignIn = ({ t, showNotification }) => {
  const currentLocation = useLocation();

  const [isSignInVisible, setIsSignInVisible] = useState(false);

  const [webSignInWithGoogle] = useMutation(WEB_SIGN_IN_WITH_GOOGLE);
  const [webSignInWithApple] = useMutation(WEB_SIGN_IN_WITH_APPLE);

  // const { isMobile } = useViewport();

  useEffect(() => {
    const forceSign = async () => {
      const params = new URLSearchParams(currentLocation.search);
      const authToken = params.get('authToken');
      if (authToken) {
        await loginWithToken(authToken);
      }
    };
    forceSign();
  }, []);

  const handleGoogleSignIn = async (response) => {
    const googleUser = jwt_decode(response.credential);
    let authResponse = null;
    try {
      authResponse = await webSignInWithGoogle({
        variables: {
          token: response.credential,
          userId: googleUser.sub,
        },
      });
    } catch (e) {
      // console.log(e);
      showNotification({
        title: t('Notifications.GoogleAccount'),
        message: t('Notifications.AuthError'),
        type: 'ERROR',
      });
    }
    const authToken = authResponse?.data?.webSignInWithGoogle?.token;
    if (authToken) {
      await loginWithToken(authToken);
    }
  };

  const onAppleAuthSuccess = async (response) => {
    const token = response?.authorization?.id_token;
    const appleUser = jwt_decode(token);
    try {
      if (token) {
        const authResponse = await webSignInWithApple({
          variables: {
            token,
            userId: appleUser.sub,
          },
        });
        const authToken = authResponse?.data?.webSignInWithApple?.token;
        if (authToken) {
          await loginWithToken(authToken);
        }
      }
    } catch (e) {
      // console.log(e);
      showNotification({
        title: t('Notifications.AppleAccount'),
        message: t('Notifications.AuthError'),
        type: 'ERROR',
      });
    }
  };

  useEffect(() => {
    if (global?.google?.accounts?.id?.initialize) {
      global.google.accounts.id.initialize({
        client_id:
          '1073503003137-27pjstclsrb1ger3tmkklbuugqt6oct4.apps.googleusercontent.com',
        callback: handleGoogleSignIn,
      });
    }
    if (global?.google?.accounts?.id?.renderButton) {
      global.google.accounts.id.renderButton(
        document.getElementById('googleSignInDiv'),
        { theme: 'outline', size: 'large' }
      );
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name='description'
          content='Российский social-discovery сервис для знакомств со встроенным ИИ-ассистентом на основе совместимости. Позволь искусственному интеллекту найти твою идеальную пару.'
        />
      </Helmet>
      <PageWrap>
        <Header onSignIn={() => setIsSignInVisible(true)} />
        {/* <Wrap>
          <ContentContainer>
            <SmallLogoWrap>
              <Logo />
            </SmallLogoWrap>
            <PageTitle>{t('main.welcomeToFlero')}</PageTitle>
            <SectionWrap>
              <TokenSignIn />
            </SectionWrap>
            <SocialSignInContainer>
              <GoogleButtonContainer>
                <div id='googleSignInDiv'></div>
              </GoogleButtonContainer>
              <AppleSignin
                authOptions={{
                  clientId: 'com.microcosm.microcosm.payment',
                  scope: 'email name',
                  redirectURI: 'https://flero.io',
                  state: 'state',
                  nonce: 'nonce',
                  usePopup: true,
                }}
                uiType='light'
                className='apple-auth-btn'
                noDefaultStyle={false}
                buttonExtraChildren={t('main.continueWithApple')}
                onSuccess={onAppleAuthSuccess}
                onError={(error) => console.error(error)}
                skipScript={false}
              />
            </SocialSignInContainer>
            <BottomContainer>
              <PolicyText>{t('main.authorizationText')}</PolicyText>
              <PolicyLinksContainer>
                <PolicyLink href='/policy' target='_blank'>
                  {t('main.authorizationText2')}
                </PolicyLink>
                {` ${t('main.and')} `}
                <PolicyLink href='/eula' target='_blank'>
                  {t('main.authorizationText3')}
                </PolicyLink>
              </PolicyLinksContainer>
            </BottomContainer>
            <Cont>
              <PolicyText>{t('main.noAccountMessage')}</PolicyText>
            </Cont>
            <Cont isBottom>
              <GetAppButton isApple />
              <GetAppButton />
            </Cont>
          </ContentContainer>
        </Wrap>
        <LogoWrap>
          <Logo large />
        </LogoWrap> */}
      </PageWrap>
      <VideoWrap>
        <Video autoPlay loop muted playsInline>
          <source src={BG_VIDEO} type='video/mp4' />
          Your browser does not support the video tag.
        </Video>
      </VideoWrap>
      <Surrounding />
      <Analyze />
      <Assistent />
      <MentalId />
      <ChatInvent />
      <Audience />
      <GradientPromo />
      <Footer />
      {isSignInVisible ? (
        <Modal onClose={() => setIsSignInVisible(false)} fullScreen>
          <Wrap>
            <ContentContainer>
              <SmallLogoWrap>
                <Logo />
              </SmallLogoWrap>
              <PageTitle>{t('main.welcomeToFlero')}</PageTitle>
              <SectionWrap>
                <TokenSignIn />
              </SectionWrap>
              <SocialSignInContainer>
                <GoogleButtonContainer>
                  <div id='googleSignInDiv'></div>
                </GoogleButtonContainer>
                <AppleSignin
                  authOptions={{
                    clientId: 'com.microcosm.microcosm.payment',
                    scope: 'email name',
                    redirectURI: 'https://flero.io',
                    state: 'state',
                    nonce: 'nonce',
                    usePopup: true,
                  }}
                  uiType='light'
                  className='apple-auth-btn'
                  noDefaultStyle={false}
                  buttonExtraChildren={t('main.continueWithApple')}
                  onSuccess={onAppleAuthSuccess}
                  onError={(error) => console.error(error)}
                  skipScript={false}
                />
              </SocialSignInContainer>
              <BottomContainer>
                <PolicyText>{t('main.authorizationText')}</PolicyText>
                <PolicyLinksContainer>
                  <PolicyLink href='/policy' target='_blank'>
                    {t('main.authorizationText2')}
                  </PolicyLink>
                  {` ${t('main.and')} `}
                  <PolicyLink href='/eula' target='_blank'>
                    {t('main.authorizationText3')}
                  </PolicyLink>
                </PolicyLinksContainer>
              </BottomContainer>
              <Cont>
                <PolicyText>{t('main.noAccountMessage')}</PolicyText>
              </Cont>
              <Cont isBottom>
                <GetAppButton isApple />
                <GetAppButton />
              </Cont>
            </ContentContainer>
          </Wrap>
        </Modal>
      ) : null}
    </>
  );
};

const PageWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  ${(p) => p.theme.max('sm')`
    margin: 0;
  `}
`;

const Wrap = styled.div`
  width: 50%;
  z-index: 99;
  padding: 0 40px;
  /* border-right: 1px black dotted; */
  margin-left: auto;
  margin-right: auto;
  ${(p) => p.theme.max('sm')`
    width: 100%;
    border-right: none;
    padding: 0 20px;
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding-bottom: 24px;
  ${(p) => p.theme.max('sm')`
    padding: 0 12px;
    position: relative;
    text-wrap: balance;
    padding-bottom: 20px;
  `}
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 60px;
  align-items: center;
`;

const PageTitle = styled.h2`
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  text-align: center;
  color: ${(p) => p.theme.colors.black};
  white-space: initial;
  ${(p) => p.theme.max('sm')`
    font-size: 50px;
    line-height: 50px;
  `}
`;

const PolicyText = styled(Text)`
  color: ${(p) => p.theme.colors.black};
  margin-bottom: 0;
  ${(p) => p.theme.max('sm')`
    text-align: center;
    margin-top: 0;
  `}
`;

const PolicyLink = styled.a`
  color: ${(p) => p.theme.colors.black};
  text-decoration: none;
  ${(p) => p.theme.max('sm')`
    white-space: break-spaces;
    text-align: center;
	`}
`;

const GoogleButtonContainer = styled.div`
  margin-right: 12px;
  ${(p) => p.theme.max('sm')`
    margin-right: 0;
    margin-top: 12px;
  `}
`;

const SocialSignInContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) => p.theme.max('sm')`
    flex-direction: column-reverse;
  `}
  .apple-auth-btn {
    width: 216px;
    height: 42px;
  }
`;

const Cont = styled.div`
  display: flex;
  justify-content: center;
  ${(p) =>
    p.isBottom &&
    css`
      padding-top: 12px;
    `}
  a {
    text-decoration: none;
  }
`;

const LogoWrap = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) => p.theme.max('sm')`
    display: none;
  `}
`;

const SmallLogoWrap = styled.div`
  display: none;
  ${(p) => p.theme.max('sm')`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 42px 0;
  `}
`;

const PolicyLinksContainer = styled.p`
  text-align: center;
`;

const VideoWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const Video = styled.video`
  width: 100%;
  max-width: 620px;
  z-index: 1;
`;

export default withInAppNotifications(withTranslation()(SignIn));
