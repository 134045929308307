import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Container, Text, SectionSecond } from 'core/ui/atoms';
import Apple from '../../../assets/images/icons/apple';
import GooglePlay from '../../../assets/images/icons/googlePlay';

const GradientPromo = () => {
  const { t } = useTranslation();

  const urlOpener = () => {
    window.open('https://flero.onelink.me/45KZ/d1l7019q');
  };

  return (
    <Container>
      <Screen>
        <TextContainer>
          <SectionSecond white center>
            {t('gradientPromo')}
          </SectionSecond>
        </TextContainer>

        <StyledBox>
          <Button onClick={urlOpener} fulfilled>
            <Apple />
            <Text bold>App Store</Text>
          </Button>
          <Button onClick={urlOpener}>
            <GooglePlay />
            <Text bold>Google Play</Text>
          </Button>
        </StyledBox>
      </Screen>
    </Container>
  );
};

const Screen = styled.div`
  height: 80vh;
  width: 100%;
  border-radius: 36px;
  background: linear-gradient(135deg, #ff2f13 39.58%, #ff8126 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  justify-content: center;
`;
const StyledBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
`;
const TextContainer = styled.div`
  max-width: 418px;
`;

const Button = styled.button`
  display: flex;
  gap: 5px;
  width: 240px;
  padding: 24px 56px;
  border: 1px solid white;
  justify-content: center;
  color: white;
  border-radius: 16px;
  background-color: transparent;
  ${(p) =>
    p.fulfilled &&
    css`
      background-color: white;
      color: black;
    `}
`;

export default GradientPromo;
