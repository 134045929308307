import React from 'react';
import WebContainer from 'core/ui/atoms/WebContainer';
import POLICY from './policy';

const PolicyPage = () => {
  return (
    <WebContainer>
      <div dangerouslySetInnerHTML={{ __html: POLICY }}></div>
    </WebContainer>
  );
};

export default PolicyPage;
