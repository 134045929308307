import React from 'react';
import styled from 'styled-components';
import { Container, Text, Box } from 'core/ui/atoms';

const Footer = () => {
  return (
    <Container>
      <CustomContainer>
        <Box
          style={{
            display: 'flex',
            gap: '20px',
            flexWrap: 'wrap',
            marginBottom: '20px',
            justifyContent: 'center',
          }}
        >
          <Box>
            <CustomA href='/policy'>
              <Text>Политика конфиденциальности</Text>
            </CustomA>
          </Box>
          <Box>
            <CustomA href='/eula'>
              <Text>Пользовательское соглашение</Text>
            </CustomA>
          </Box>
          <Box>
            <CustomA href='/rules'>
              <Text>Правила сообщества</Text>
            </CustomA>
          </Box>
          <Box>
            <CustomA href='/safety'>
              <Text>Правила безопасного знакомства</Text>
            </CustomA>
          </Box>
          <Box>
            <CustomA href='/payments'>
              <Text>Соглашение на совершение рекуррентных платежей</Text>
            </CustomA>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap-reverse',
          }}
        >
          <Box>
            <Text align={'center'}></Text>
          </Box>
          <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Text style={{ margin: 'auto' }}>
              Для жалоб и предложений, пиши на почту &nbsp;
            </Text>{' '}
            <CustomA href='mailto: support@flero.io'>
              support@flero.io
            </CustomA>
          </Box>
        </Box>
      </CustomContainer>
    </Container>
  );
};

const CustomA = styled.a`
  margin: auto;
  display: block;
  color: black;
  text-decoration: none;
  border-bottom: 1px solid black;
  ${p => p.theme.max('sm')`
    text-decoration: underline;
    text-underline-offset: 6px;
    text-align: center;
    border-bottom: none;
  `}
`;

const CustomContainer = styled(Container)`
  padding-top: 40px;
  padding-bottom: 40px;
`;

export default Footer;
