import React from 'react';
import styled from 'styled-components';

import { getFullAvatar } from '../../api/helpers';
import MASK from '../../assets/images/avatar_mask.svg';
import WRAP from '../../assets/images/avatar_wrap.svg';
import MASK_SMALL from '../../assets/images/avatar_mask_small.svg';
import WRAP_SMALL from '../../assets/images/avatar_wrap_small.svg';

const MaskedAvatar = ({ avatar, small }) => {
  return small ? (
    <AvatarWrapSmall>
      <AvatarSmall src={getFullAvatar(avatar)} avatar={getFullAvatar(avatar)} />
    </AvatarWrapSmall>
  ) : (
    <AvatarWrap small={small}>
      <Avatar src={getFullAvatar(avatar)} avatar={getFullAvatar(avatar)} />
    </AvatarWrap>
  );
};

const AvatarWrap = styled.div`
  background-image: url(${WRAP});
  width: 868px;
  height: 916px;
  transform: scale(0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 724px;
  height: 699px;
  mask-image: url(${MASK});
  -webkit-mask-image: url(${MASK});
`;

const AvatarWrapSmall = styled.div`
  background-image: url(${WRAP_SMALL});
  width: 130px;
  height: 137px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
`;

const AvatarSmall = styled.img`
  object-fit: cover;
  width: 108px;
  height: 104px;
  mask-image: url(${MASK_SMALL});
  -webkit-mask-image: url(${MASK_SMALL});
`;

export default MaskedAvatar;
