import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useMutation } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import LogoutButton from '../components/LogoutButton';
import BackButton from '../components/BackButton';
import Subscriptions from '../components/Subscriptions';
import CurrentSubscription from '../components/CurrentSubscription';
import { Button, SectionWrap, Logo, Text, Confirm } from '../atoms';
import { withAccount } from 'core/api/accounts/accountContext';
import { CURRENT_USER } from 'core/api/apollo/queries';
import {
  INIT_CLOUD_PAYMENT,
  CANCEL_CLOUD_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  CLEAR_SUBSCRIPTION,
} from 'core/api/apollo/mutations';
import { GET_USER_DISCOUNT } from 'core/api/apollo/queries';

import LEFT_DECORATION from '../../assets/images/decoration_2_1.svg';
import RIGHT_DECORATION from '../../assets/images/decoration_2_2.svg';
import InAppFooter from '../components/InAppFooter';

const FleroSubscriptions = ({ t, currentUser, refetchCurrentUser }) => {
  const [subscription, setSubscription] = useState();
  const [loading, setLoading] = useState(false);
  const [unsubscribing, setUnsubscribing] = useState(false);
  const [clearing, setClearing] = useState(false);
  const [showCancelSubConfirm, setShowCancelSubConfirm] = useState(false);
  const [showClearSubConfirm, setShowClearSubConfirm] = useState(false);
  const [showInfoApp, setShowInfoApp] = useState(false);
  const [showSubscriptionСonfirm, setShowSubscriptionConfirm] = useState(false);
  const [showSubscriptions, setShowSubscriptions] = useState(false);
  const [changeSubscription, setChangeSubscription] = useState(false);

  const {
    data: discountData,
    // loading: discountLoading,
    // error: discountError,
  } = useQuery(GET_USER_DISCOUNT);

  const discount =
    (discountData &&
      discountData.getUserDiscount &&
      discountData.getUserDiscount.discount) ||
    0;

  const toggleChangeSubscription = () => {
    setChangeSubscription(!changeSubscription);
  };

  const [policyChecked, setPolicyChecked] = useState(false);

  const payments = currentUser?.serviceData?.payments;
  const {
    plan,
    expirationDate,
    giftPlan,
    giftPlanUntil,
    webPlan,
    expirationDateWeb,
    recurrentPayment,
  } = payments || {};

  // const PLANS = {
  //   black: 3,
  //   premium: 2,
  //   standard: 1,
  //   free: 0,
  // };

  const actualPlan = () => {
    const now = Date.now();
    const values = [{ plan: 'free', expirationDate: 0, type: 'none' }];
    if (webPlan && expirationDateWeb && expirationDateWeb > now) {
      values.push({
        plan: webPlan,
        expirationDate: expirationDateWeb,
        type: 'web',
      });
    }
    if (giftPlan && giftPlanUntil && giftPlanUntil > now) {
      values.push({
        plan: giftPlan,
        expirationDate: giftPlanUntil,
        type: 'gift',
      });
    }
    if (plan && expirationDate && expirationDate > now) {
      values.push({ plan, expirationDate: expirationDate, type: 'app' });
    }
    let result = values[0];
    for (const value of values) {
      if (value.expirationDate > result.expirationDate) {
        result = value;
      }
    }
    return result;
  };

  const [initCloudPayment] = useMutation(INIT_CLOUD_PAYMENT);
  const [cancelCloudSubscription] = useMutation(CANCEL_CLOUD_SUBSCRIPTION);
  // const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION);
  const [clearSubscription] = useMutation(CLEAR_SUBSCRIPTION);

  const toggleCancelSub = () => {
    setShowCancelSubConfirm((st) => !st);
  };

  const toggleClearSub = () => {
    setShowClearSubConfirm((st) => !st);
  };

  const togglePolicy = () => {
    setPolicyChecked((st) => !st);
  };

  const purchase = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await initCloudPayment({
        variables: {
          input: {
            product: subscription.product,
            plan: subscription.plan,
            amount: subscription.amount,
            productName: subscription.title,
            days: subscription.days,
            isSubscription: true,
            discount,
            originalPrice: subscription.oldAmount,
          },
        },
      });
      const orderId = res.data.initCloudPayment.orderId;
      const widget = new window.cp.CloudPayments({
        language: 'ru-RU',
      });
      const receipt = {
        Items: [
          //товарные позиции
          {
            label: `Оплата подписки в flero.io, ${
              subscription.plan === 'standard' ? 'plus' : subscription.plan
            } на 1 месяц`, //наименование товара
            price: subscription.amount, //цена
            quantity: 1.0, //количество
            amount: subscription.amount, //сумма
            vat: 0, // ставка НДС
            method: 1, // Fullprepayment
            object: 4, // услуга
          },
        ],
        taxationSystem: 1, // Упрощенная система налогообложения (Доход)
        email: currentUser.personalData.email, //e-mail покупателя, если нужно отправить письмо с чеком
        phone: '', //телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
        isBso: false, //чек является бланком строгой отчетности
        amounts: {
          electronic: subscription.amount, // Сумма оплаты электронными деньгами
          advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после точки)
          credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после точки)
          provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после точки)
        },
      };
      widget.charge(
        {
          publicId: 'pk_1eb71f6c518517b55f5d96c94af11',
          description: `Оплата подписки в flero.io, ${
            subscription.plan === 'standard' ? 'plus' : subscription.plan
          } на 1 месяц`,
          amount: subscription.amount,
          currency: 'RUB',
          accountId: currentUser._id,
          email: currentUser.personalData.email,
          data: {
            CloudPayments: {
              CustomerReceipt: receipt, //чек для первого платежа
              recurrent: {
                interval: 'Month',
                period: 1,
                customerReceipt: receipt, //чек для регулярных платежей
              },
            },
            product: subscription.product,
            plan: subscription.plan,
            amount: subscription.amount,
            productName: subscription.title,
            days: subscription.days,
            isSubscription: true,
            orderId,
          },
          invoiceId: orderId,
          autoClose: 3,
          // skin: 'mini',
        },
        {
          onSuccess: function (options) {
            // действие при успешной оплате
            console.log('Success', options);
            setLoading(false);
            refetchCurrentUser();
          },
          onFail: function (reason, options) {
            // действие при неуспешной оплате
            console.log('Fail', reason, options);
            setLoading(false);
          },
          onComplete: function (paymentResult, options) {
            // Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
            // например вызов вашей аналитики Facebook Pixel
            console.log('Complete', paymentResult, options);
            setLoading(false);
            refetchCurrentUser();
          },
        }
      );
    } catch (e) {
      // console.log(e);
      setLoading(false);
    }
  };

  const cancel = async () => {
    if (unsubscribing) {
      return;
    }
    setUnsubscribing(true);
    try {
      await cancelCloudSubscription({
        refetchQueries: [{ query: CURRENT_USER }],
      });
      setUnsubscribing(false);
      setShowCancelSubConfirm(false);
    } catch (e) {
      setUnsubscribing(false);
    }
  };

  const clear = async () => {
    if (unsubscribing) {
      return;
    }
    setClearing(true);
    try {
      await clearSubscription({
        refetchQueries: [{ query: CURRENT_USER }],
      });
      setClearing(false);
      setShowClearSubConfirm(false);
    } catch (e) {
      setClearing(false);
    }
  };

  const getPlanSubscription = () => {
    const sub = actualPlan();
    if (showSubscriptions) {
      return (
        <>
          <PageHeading>{t('subscriptions.chooseYourPlan')}</PageHeading>
          <Subscriptions
            currentSubscription={subscription}
            setSubscription={setSubscription}
            policyChecked={policyChecked}
            togglePolicy={togglePolicy}
            discount={discount}
          />
          <SectionWrap>
            <ButtonContainer>
              <ButtonWrap
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: '20px',
                }}
              >
                <Button
                  onClick={purchase}
                  isLoading={loading}
                  disabled={!subscription || !policyChecked}
                >
                  {t('main.proceed')}
                </Button>
              </ButtonWrap>
              <PurchaseButtonDisclaimer>
                {t('main.purchaseButtonDisclaimer')}
              </PurchaseButtonDisclaimer>
            </ButtonContainer>
          </SectionWrap>
        </>
      );
    }
    if (sub.type === 'web') {
      return (
        <>
          <PageHeading>{t('subscriptions.pageTitle')}</PageHeading>
          <CurrentSubscription currentUser={currentUser} />
          <ButtonWrap
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '18px',
              marginBottom: '20px',
              marginTop: '20px',
            }}
          >
            {/* <Button
                disabled={unsubscribing}
                onClick={toggleChangeSubscription}
              >
                Выбрать другой план
              </Button> */}
            <Button
              disabled={unsubscribing}
              onClick={recurrentPayment ? toggleCancelSub : toggleClearSub}
            >
              {recurrentPayment ? 'Отменить подписку' : 'Обнулить подписку'}
            </Button>
            {/*               <AppStoreText>
                Если вы приобретали ранеее подписку через AppStore/Google Play,
                то вам необходимо отменить её в настройках подписок в
                соответсвующем магазине приложений
              </AppStoreText> */}
          </ButtonWrap>
        </>
      );
    } else if (sub.type === 'gift') {
      return (
        <>
          <PageHeading>{t('subscriptions.pageTitle')}</PageHeading>
          <CurrentSubscription currentUser={currentUser} />
          <ButtonWrap
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              gap: '18px',
              marginBottom: '20px',
              marginTop: '20px',
            }}
          >
            <Button disabled={unsubscribing} onClick={toggleChangeSubscription}>
              {'Выбрать другой план'}
            </Button>
            <Button disabled={unsubscribing} onClick={toggleClearSub}>
              {'Обнулить подписку'}
            </Button>
          </ButtonWrap>
        </>
      );
    } else if (sub.type === 'app') {
      if (sub.plan !== 'free' && !showSubscriptions) {
        return (
          <>
            <PageHeading>{t('subscriptions.pageTitle')}</PageHeading>
            <CurrentSubscription currentUser={currentUser} />
            <ButtonWrap>
              {' '}
              <ButtonWrap
                style={{
                  flexDirection: 'row',
                  gap: '18px',
                  marginBottom: '20px',
                  marginTop: '-20px',
                }}
              >
                <Button
                  disabled={unsubscribing}
                  onClick={() =>
                    setShowSubscriptionConfirm(!showSubscriptionСonfirm)
                  }
                >
                  {'Изменить подписку'}
                </Button>
                <Button
                  disabled={unsubscribing}
                  onClick={() => setShowInfoApp(!showInfoApp)}
                >
                  {'Отменить подписку'}
                </Button>
              </ButtonWrap>
              <AppStoreText>
                Для перехода на оплату подписок через наш сайт, вам необходимо
                отменить подписку в магазине приложений (AppStore/Google Play)
                для отмены дальнейших списаний. После этого перейти обратно на
                наш сайт и оформите новую подписку с подходящим вам тарифом.
                Обратите внимание что при оформлении подписки через наш сайт,
                вам не будут возвращены средства за подписку в магазине
                приложений (AppStore/Google Play).
              </AppStoreText>
            </ButtonWrap>
          </>
        );
      }
    }
    return (
      <>
        <PageHeading>{t('subscriptions.chooseYourPlan')}</PageHeading>
        <Subscriptions
          currentSubscription={subscription}
          setSubscription={setSubscription}
          policyChecked={policyChecked}
          togglePolicy={togglePolicy}
          currentUser={currentUser}
          discount={discount}
        />
        <SectionWrap>
          <ButtonContainer>
            <ButtonWrap style={{ flexDirection: 'column' }}>
              <Button
                onClick={purchase}
                isLoading={loading}
                disabled={!subscription || !policyChecked}
              >
                {t('main.proceed')}
              </Button>
            </ButtonWrap>
            <PurchaseButtonDisclaimer>
              {t('main.purchaseButtonDisclaimer')}
            </PurchaseButtonDisclaimer>
          </ButtonContainer>
        </SectionWrap>
      </>
    );
  };

  return (
    <>
      <Decoration isLeft src={LEFT_DECORATION} />
      <Decoration src={RIGHT_DECORATION} />
      <Wrap>
        <Logo />
        {getPlanSubscription()}
        <BackButton />
        {showCancelSubConfirm ? (
          <Confirm
            title={t('main.confirmTitle')}
            text={`${t(
              'main.confirmText'
            )} Доступ к предоставленному по подписке функционалу сохранится до конца оплаченного периода.`}
            cancelText={t('main.confirmCancelText')}
            confirmText={t('main.confirmConfirmText')}
            onConfirm={cancel}
            confirmPending={unsubscribing}
            onCancel={toggleCancelSub}
          />
        ) : null}
        {showClearSubConfirm ? (
          <Confirm
            title={t('main.confirmTitle2')}
            text={t('main.confirmText2')}
            cancelText={t('main.cancel')}
            confirmText={t('main.confirm')}
            onConfirm={clear}
            confirmPending={clearing}
            onCancel={toggleClearSub}
          />
        ) : null}
        {showInfoApp ? (
          <Confirm
            title={'Отмена подписки'}
            text={
              'Ваша подписка была оформлена в магазине приложений (App Store/Google Play), для отмены перейдите пожалуйста в настройки подписок в соответствующем магазине приложений и отмените подписку'
            }
            confirmText={'Понятно'}
            onConfirm={() => setShowInfoApp(!showInfoApp)}
          />
        ) : null}
        {showSubscriptionСonfirm ? (
          <Confirm
            title={'Изменить подписку'}
            text={
              'Для перехода на оплату подписок через наш сайт, вам необходимо отменить подписку в магазине приложений (AppStore/Google Play) для отмены дальнейших списаний. После этого перейти обратно на наш сайт и оформите новую подписку с подходящим вам тарифом. Обратите внимание что при оформлении подписки через наш сайт, вам не будут возвращены средства за подписку в магазине приложений (AppStore/Google Play).'
            }
            confirmText={'Согласен'}
            cancelText={'Отмена'}
            onCancel={() =>
              setShowSubscriptionConfirm(!showSubscriptionСonfirm)
            }
            onConfirm={() => {
              setShowSubscriptions(!showSubscriptions);
              setShowSubscriptionConfirm(!showSubscriptionСonfirm);
            }}
          />
        ) : null}
        {changeSubscription ? (
          <Confirm
            title={'Хотите выбрать новую подписку?'}
            text={
              'Текущий оплаченный период будет анулирован без возврата денежных средств'
            }
            confirmText={'Согласен'}
            cancelText={'Отмена'}
            onCancel={() => setChangeSubscription(!changeSubscription)}
            onConfirm={() => {
              setShowSubscriptions(!showSubscriptions);
              setChangeSubscription(!changeSubscription);
            }}
          />
        ) : null}
        <LogoutButton />
      </Wrap>
      <InAppFooter />
    </>
  );
};

const Wrap = styled.div`
  z-index: 99;
  padding: 0 16px;
`;

const PageHeading = styled(Text)`
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  color: ${(p) => p.theme.colors.black};
  text-align: center;
  ${(p) => p.theme.max('sm')`
		font-size: 26px;
		line-height: 32px;
		text-align: center;
	`}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PurchaseButtonDisclaimer = styled(Text)`
  color: ${(p) => p.theme.colors.grays.regular};
  text-align: center;
`;

const Decoration = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  ${(p) =>
    p.isLeft &&
    css`
      top: auto;
      right: auto;
      left: 0;
      bottom: 0;
    `}
  ${(p) => p.theme.max('sm')`
    display: none;
  `}
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
  ${(p) => p.theme.max('sm')`
    margin-top: 40px;
    flex-direction: column !important;
  `}
`;

const AppStoreText = styled(Text)`
  text-wrap: balance;
  text-align: center;
`;

export default withTranslation()(withAccount(FleroSubscriptions));
