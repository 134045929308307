import React from 'react';

function Success() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      fill='none'
      viewBox='0 0 25 24'
    >
      <path
        stroke='gray'
        strokeLinecap='round'
        strokeWidth='0.5'
        d='M24 12c0 6.351-5.149 11.5-11.5 11.5S1 18.351 1 12 6.149.5 12.5.5 24 5.649 24 12z'
      ></path>
      <path
        stroke='gray'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M17.5 9l-6 6-3.535-3.536'
      ></path>
    </svg>
  );
}

export default Success;
