import { gql } from '@apollo/client';

export const loggedUserData = gql`
fragment LoggedUserData on User {
  _id
  createdAt
  isLiked
  online
  lastOnlineAt
  personalData {
    firstName
    email
    phone
    avatar
    images
    tags
    about
    info
    gender
    johari
  }
  serviceData {
    shareCode
    locale
    city
    birthYear
    birthMonth
    birthDay
    birthHour
    birthMinute
    birthDateBlock
    birthCityBlock
    soulmates
    lat
    lng
    city
    cityId
    offset
    frozen
    blackList
    chatToken
    isSystem
    isBanned
    code
    showInvite
    completeProfileModalShown
    instagram {
      token
      userName
    }
    notifications {
      send
      discussions
      messages
      likes
      token
    }
    verification {
      verified
      lastVerified
    }
    location {
      coordinates
    }
    systems {
      esotherics
      psychology
    }
    privacy {
      visibility
      hideAge
      hideIcon
    }
    payments {
      plan
      gifts
      giftsChat
      superLikes
      calculation
      giftPlan
      giftPlanUntil
      webPlan
      expirationDate
      expirationDateWeb
      recurrentPayment
      mrr
      price
      webDiscount
      webDiscountType
      webDiscountDate
    }
    isReviewShown
  }
  tests
  username
  images {
    id
    mediaUrl
    mediaType
    username
  }
}
`;

export const promocodeData = gql`
  fragment PromocodeData on Promocode {
    code
    type
    notSelf
    notWithActive
    activated
    expirationDate
    expirationDateFull
    ownerId
    userId
    days
    product
    isActive
  }
`;