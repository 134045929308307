import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Container, Text, SectionHeading } from 'core/ui/atoms';
import Success from '../../../../assets/images/icons/success';
import Cards from './Cards';

const ChatInvent = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <HeaderSeparator>
        <SectionHeading>{t('chat.title')}</SectionHeading>
      </HeaderSeparator>
      <StyledBox>
        <TextContainer>
          <IconContainer>
            <Success />
          </IconContainer>

          <Text primary>{t('chat.text1')}</Text>
        </TextContainer>
        <TextContainer>
          <IconContainer>
            <Success />
          </IconContainer>

          <Text primary>{t('chat.text2')}</Text>
        </TextContainer>
        <TextContainer>
          <IconContainer>
            <Success />
          </IconContainer>

          <Text primary>{t('chat.text3')}</Text>
        </TextContainer>
      </StyledBox>
      <Cards />
      <StyledHr />
    </Container>
  );
};
const HeaderSeparator = styled.div`
  padding-bottom: 70px;
  margin-top: 70px;
  /* width: 500px; */
  ${(p) => p.theme.max('md')`
    /* width: 300px; */
    padding-top: 0;
    margin-top: 60px;
  `}
`;

const TextContainer = styled.div`
  width: 583px;
  display: flex;
  ${(p) => p.theme.max('md')`
    width: auto;
    `}
`;
const StyledHr = styled.hr`
  background-color: #e0e3e7;
  height: 1px;
  border: none;
  margin-top: 40px;
  margin-bottom: 80px;
  ${(p) => p.theme.max('md')`
        margin-bottom: 20px;
    `}
`;
const IconContainer = styled.div`
  padding-right: 16px;
`;
const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 24px;
  padding-bottom: 80px;
  ${(p) => p.theme.max('md')`
        align-items: start;
    `}
`;

export default ChatInvent;
