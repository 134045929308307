import React, { useState } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { Input, Text, Button } from 'core/ui/atoms';
import { loginWithToken } from 'core/api/actions';
import { withInAppNotifications } from 'core/api/toast/withInAppNotifications';
import { SIGN_IN_WITH_TOKEN } from 'core/api/apollo/mutations';

const TokenSignIn = ({ t, showNotification }) => {
  const [token, setToken] = useState('');
  const [pending, setPending] = useState(false);

  const [signInWithToken] = useMutation(SIGN_IN_WITH_TOKEN);

  const onSignIn = async () => {
    if (pending) {
      return;
    }
    setPending(true);
    try {
      const authResponse = await signInWithToken({
        variables: {
          token,
        },
      });
      const authToken = authResponse?.data?.signInWithToken?.token;
      if (authToken) {
        await loginWithToken(authToken);
      }
      setToken('');
      setPending(false);
    } catch (e) {
      console.log(e.message);
      if (e.message === 'AuthenticationError: Token authentication disabled') {
        showNotification({
          title: t('Notifications.LoginError'),
          message: t('Notifications.TokenDisabled'),
          type: 'ERROR',
        });
      } else if (e.message === 'AuthenticationError: Incorrect token') {
        showNotification({
          title: t('Notifications.LoginError'),
          message: t('Notifications.WrongToken'),
          type: 'ERROR',
        });
      }
      setPending(false);
    }
  };

  return (
    <Container>
      <Label>Вход с токеном</Label>
      <Input
        value={token}
        onChange={(event) => setToken(event.currentTarget.value)}
        placeholder={'Токен'}
      />
      <Text color={'grays.regular'} style={{ marginTop: 8 }}>
        Токен находится в настройках вашего профиля в приложении (для
        использования данного способа авторизации у вас должен быть включен
        способ входа по токену в настройках)
      </Text>
      <ButtonCont>
        <Button
          onClick={onSignIn}
          fullWidth
          isLoading={pending}
          disabled={!token}
        >
          {t('main.signIn')}
        </Button>
      </ButtonCont>
    </Container>
  );
};

const Container = styled.div`
  max-width: 578px;
  margin-right: auto;
  margin-left: auto;
`;

const Label = styled(Text)`
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: ${(p) => p.theme.colors.black};
  ${(p) => p.theme.max('sm')`
    margin-bottom: 14px;
    font-size: 22px;
  `}
`;

const ButtonCont = styled.div`
  width: 100%;
  margin-top: 60px;
  ${(p) => p.theme.max('sm')`
    margin-top: 40px;
  `}
`;

export default withInAppNotifications(withTranslation()(TokenSignIn));
