import { gql } from '@apollo/client';

import { loggedUserData, promocodeData } from './fragments';

export const CURRENT_USER = gql`
  query me {
    me {
      ...LoggedUserData
    }
  }
  ${loggedUserData}
`;

export const USER_PROMOCODES = gql`
  query UserPromocodes {
    userPromocodes {
      ...PromocodeData
    }
  }
  ${promocodeData}
`;

export const GET_USER_DISCOUNT = gql`
  query GetUserDiscount {
    getUserDiscount {
      type
      discount
      expirationDate
      error
    }
  }
`;
