import React from 'react';
import styled from 'styled-components';
import { Text } from 'core/ui/atoms';
import Palletes from '../../../../assets/images/radiant.png';
import Circles from '../../../../assets/images/circles.png';
import Iphone from '../../../../assets/images/iphone_5.png';

const Cards = () => {
  return (
    <GridContainer>
      <Box3>
        <Img src={Iphone} />
      </Box3>
      <Box1>
        <Img2 src={Palletes} />
        <Text>Я помогу с ответом...</Text>
      </Box1>
      <Box2>
        <Img3 src={Circles} />
      </Box2>
    </GridContainer>
  );
};
const GridContainer = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-auto-rows: 50%;
  grid-template-columns: repeat(2, 1fr);
  ${(p) => p.theme.max('md')`
        display: flex;
        flex-direction: column;
        gap: 20px;
    `}
`;
const Box1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 36px;
  height: 500px;
  position: relative;
  background-color: ${(p) => p.theme.colors.secondaryLanding};
  ${(p) => p.theme.max('md')`
height: 300px;
`}
`;
const Box2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  height: 500px;
  position: relative;
  background-color: ${(p) => p.theme.colors.secondaryLanding};
  grid-column-start: 2;
  ${(p) => p.theme.max('md')`
height: 300px;
`}
`;
const Box3 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  position: relative;
  background-color: ${(p) => p.theme.colors.secondaryLanding};
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 3;
  ${(p) => p.theme.max('md')`
height: 500px;
`}
`;
const Img = styled.img`
  width: 348px;
  ${(p) => p.theme.max('md')`
    object-fit: contain;
    width: 100%;
    height: 80%;
    width: 300px`}
`;
const Img2 = styled.img`
  width: 398px;
  ${(p) => p.theme.max('md')`
    object-fit: contain;
    width: 100%;
    height: 80%;
    width: 300px`}
`;
const Img3 = styled.img`
  height: 358px;
  ${(p) => p.theme.max('md')`
    object-fit: contain;
    width: 100%;
    height: 80%;
    width: 300px`}
`;

export default Cards;
