import React from 'react';

function GooglePlay() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='19'
      fill='none'
      viewBox='0 0 18 19'
    >
      <path
        fill='#fff'
        fillRule='evenodd'
        d='M13.293 11.989l-2.694-2.757L13.29 6.46a447.6 447.6 0 003.682 2.065c.917.504.451 1.153 0 1.398-.097.05-.91.507-2.116 1.185h-.001l-.612.345-.951.535zm-.49.276L10.22 9.62l-8.63 8.883c.105-.032.215-.08.333-.147l.619-.346.017-.01h.001c1.776-.993 6.653-3.72 10.242-5.736zM.885 18.45C.633 18.275.5 17.91.5 17.449V1.086C.5.577.676.264.92.103l8.92 9.13L.886 18.45zM1.579 0l8.64 8.843 2.572-2.648C11.196 5.305 2.5.45 1.923.136A1.099 1.099 0 001.579 0z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}

export default GooglePlay;
