import styled, { css } from 'styled-components';

// import { getBorderColor } from 'core/ui/helpers';

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0.6em 0;
  border: none;
  border-bottom: 1px solid #8F8F8F;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  color: ${p => p.theme.colors.black};
  :read-only {
    border-color: ${p => p.theme.colors.grays.light};
    color: ${p => p.theme.colors.grays.regular};
  }
  :focus-visible {
    outline: 0;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${p => p.theme.colors.grays.light};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${p => p.theme.colors.grays.light};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${p => p.theme.colors.grays.light};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${p => p.theme.colors.grays.light};
  }
  ${p =>
    p.error &&
    css`
      border-color: ${p => p.theme.colors.danger};
    `};
  ${p => p.theme.max('sm')`
    padding: 0.6em 1.3em;
    font-size: 22px;
  `}
`;

export default Input;
