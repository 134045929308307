// Layouts
import React from 'react';
import { Redirect } from 'react-router-dom';
import PublicLayout from 'core/ui/Layouts/PublicLayout';
// import AuthenticatedLayout from 'core/ui/Layouts/AuthenticatedLayout';

// Pages
import SignIn from 'core/ui/pages/SignIn';
import FleroProfile from 'core/ui/pages/FleroProfile';
import FleroSubscriptions from 'core/ui/pages/FleroSubscriptions';
import FleroPurchases from 'core/ui/pages/FleroPurchases';
import SuccessPayment from 'core/ui/pages/SuccessPayment';

import PolicyPage from 'core/ui/pages/documents/policy';
import SafetyPage from 'core/ui/pages/documents/safety';
import RulesPage from 'core/ui/pages/documents/rules';
import PaymentsPage from 'core/ui/pages/documents/payments';
import EulaPage from 'core/ui/pages/documents/eula';

export default [
  {
    component: SignIn,
    layout: PublicLayout,
    isPublic: true,
    redirectLogged: true,
    path: '/sign-in'
  },
  {
    component: SuccessPayment,
    layout: PublicLayout,
    isPublic: true,
    path: '/success'
  },
  {
    component: FleroSubscriptions,
    layout: PublicLayout,
    isPublic: false,
    path: '/subscriptions'
  },
  {
    component: FleroPurchases,
    layout: PublicLayout,
    isPublic: false,
    path: '/purchases'
  },
  // Documents
  {
    component: PolicyPage,
    layout: PublicLayout,
    isPublic: true,
    path: '/policy'
  },
  {
    component: SafetyPage,
    layout: PublicLayout,
    isPublic: true,
    path: '/safety'
  },
  {
    component: EulaPage,
    layout: PublicLayout,
    isPublic: true,
    path: '/EULA'
  },
  {
    component: PaymentsPage,
    layout: PublicLayout,
    isPublic: true,
    path: '/payments'
  },
  {
    component: RulesPage,
    layout: PublicLayout,
    isPublic: true,
    path: '/rules'
  },
  {
    component: FleroProfile,
    layout: PublicLayout,
    isPublic: false,
    path: '/'
  },
];
