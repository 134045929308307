import styled, { css } from 'styled-components';

const SectionHeading = styled.h2`
  font-family: TT Norms, Arial, sans-serif;
  font-weight: 600;
  font-size: 39px;
  line-height: 49.92px
  letter-spacing: 0.2em;
  width: 100%;
  text-align: center;
  vertical-align: top;
  padding: 0;
  margin: 0;
  margin-bottom: 28px;
  color: ${p => p.theme.colors.black};
  ${p => p.white && css`
    color: ${p => p.theme.colors.black};
  `}
  ${p => p.primary && css`
    color: ${p.theme.colors.primary};
  `}
  ${p => p.noMargin && css`
    margin-bottom: 0;
  `}
  ${p => p.theme.max('sm')`
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 22px;
  `}
  // ${p => p.theme.max('sm')`
  //   font-size: 33px;
  //   line-height: 56px;
  //   margin-bottom: 36px;
  // `}
  // ${p => p.theme.between('sm', 'md')`
  //   font-size: 49px;
  // `}
`;

export default SectionHeading;
