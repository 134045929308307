import React from 'react';
import WebContainer from 'core/ui/atoms/WebContainer';
import SAFETY from './safety';

const SafetyPage = () => {
  return (
    <WebContainer>
      <div dangerouslySetInnerHTML={{ __html: SAFETY }}></div>
    </WebContainer>
  );
};

export default SafetyPage;
