import React from 'react';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Checkbox, Flex, Section, Text } from '../atoms';
import CHECKBOX_EMPTY from 'core/assets/images/checkbox_empty.svg';
import CHECKBOX_FULL from 'core/assets/images/checkbox_full.svg';

const getDiscountPrice = (price, discount) => {
  if (discount && discount > 0 && discount < 100) {
    return parseInt((price * (100 - discount)) / 100);
  } else {
    return price;
  }
};

const STANDART_PRICE = 149;
const PREMIUM_PRICE = 599;
const BLACK_PRICE = 999;

const Subscriptions = ({
  t,
  currentUser,
  currentSubscription,
  setSubscription,
  policyChecked,
  togglePolicy,
  discount,
}) => {
  const webDiscount = discount && discount > 0;

  const PLANS = [
    // {
    //   id: 1,
    //   // title: t('Plans.plus'),
    //   title: 'PLUS',
    //   periodText: t('Plans.monthlyPayment'),
    //   oldAmount: STANDART_PRICE,
    //   amount: webDiscount
    //     ? getDiscountPrice(STANDART_PRICE, discount)
    //     : STANDART_PRICE,
    //   product: 'standard.1month',
    //   days: 30,
    //   plan: 'standard',
    //   advantage1: t('Plans.PlusAdvantage1'),
    //   advantage2: t('Plans.PlusAdvantage2'),
    //   advantage3: t('Plans.PlusAdvantage3'),
    // },
    {
      id: 2,
      // title: t('Plans.premium'),
      title: 'PREMIUM',
      periodText: t('Plans.monthlyPayment'),
      oldAmount: PREMIUM_PRICE,
      amount: webDiscount
        ? getDiscountPrice(PREMIUM_PRICE, discount)
        : PREMIUM_PRICE,
      product: 'premium.1month',
      days: 30,
      plan: 'premium',
      advantage1: t('Plans.PremiumAdvantage1'),
      advantage2: t('Plans.PremiumAdvantage2'),
      advantage3: t('Plans.PremiumAdvantage3'),
    },
    {
      id: 3,
      // title: t('Plans.black'),
      title: 'BLACK',
      periodText: t('Plans.monthlyPayment'),
      oldAmount: BLACK_PRICE,
      amount: webDiscount
        ? getDiscountPrice(BLACK_PRICE, discount)
        : BLACK_PRICE,
      product: 'black.1month',
      days: 30,
      plan: 'black',
      advantage1: t('Plans.BlackAdvantage1'),
      advantage2: t('Plans.BlackAdvantage2'),
      advantage3: t('Plans.BlackAdvantage3'),
    },
  ];

  const onSelect = (t) => {
    setSubscription(t);
    setTimeout(() => {
      if (window && window.scrollTo) {
        window.scrollTo({
          left: 0,
          top: document?.body?.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 50);
  };

  return (
    <CustomSection>
      <TiersWrap direction={'column'}>
        {/* <TopText>{t('Tiers.topText')}</TopText> */}
        <TiersWrap>
          {PLANS.map((t, i) => (
            <ItemWrapOut key={i} isActive={currentSubscription?.id === t.id}>
              <ItemWrap
                isActive={currentSubscription?.id === t.id}
                onClick={() => onSelect(t)}
                isLast={i === 3}
              >
                <ItemTextWrap>
                  <ItemText isActive={currentSubscription?.id === t.id}>
                    {t.title}
                  </ItemText>
                </ItemTextWrap>
                {webDiscount ? (
                  <ItemPrice isActive={currentSubscription?.id === t.id} isOld>
                    {t.oldAmount}.00 ₽
                  </ItemPrice>
                ) : null}
                <ItemPrice isActive={currentSubscription?.id === t.id}>
                  {t.amount}.00 ₽
                </ItemPrice>
                <ItemTextSub isActive={currentSubscription?.id === t.id}>
                  {t.periodText}
                </ItemTextSub>
                <AdvantagesTextWrap>
                  <ItemAdvantageText
                    isActive={currentSubscription?.id === t.id}
                  >
                    {t.advantage1}
                  </ItemAdvantageText>
                  <ItemAdvantageText
                    isActive={currentSubscription?.id === t.id}
                  >
                    {t.advantage2}
                  </ItemAdvantageText>
                  <ItemAdvantageText
                    isActive={currentSubscription?.id === t.id}
                  >
                    {t.advantage3}
                  </ItemAdvantageText>
                </AdvantagesTextWrap>
                <CheckWrap>
                  {
                    <img
                      src={
                        t.id === currentSubscription?.id
                          ? CHECKBOX_FULL
                          : CHECKBOX_EMPTY
                      }
                      alt={'checkbox'}
                    />
                  }
                </CheckWrap>
              </ItemWrap>
            </ItemWrapOut>
          ))}
        </TiersWrap>
      </TiersWrap>
      <BottomContainer>
        <Checkbox checked={policyChecked} onChecked={togglePolicy}>
          <div>
            <PolicyText>Я согласен с</PolicyText>
            <PolicyLink href='/payments' target='_blank'>
              Условиями оферты о рекуррентных платежах
            </PolicyLink>
            <PolicyText>{' и '}</PolicyText>
            <PolicyLink href='/policy' target='_blank'>
              Обработкой персональных данных
            </PolicyLink>
          </div>
        </Checkbox>
      </BottomContainer>
    </CustomSection>
  );
};

const CustomSection = styled(Section)`
  padding: 0;
  margin: 0 auto;
  max-width: 1245px;
  padding-top: 60px;
  ${(p) => p.theme.max('md')`
    margin-bottom: 82px;
 `}
  ${(p) => p.theme.max('sm')`
    padding: 40px 0 0 0;
    margin-bottom: 40px;
 `}
`;

const TiersWrap = styled(Flex)`
  justify-content: center;
  align-items: stretch;
  max-width: 1245px;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-left: -15px;
  padding-right: -15px;
  width: 100%;
  ${(p) => p.theme.max('md')`
        justify-content: center;
        
     `}
`;

const ItemWrapOut = styled(Flex)`
  border: 6px solid
    ${(p) => (p.isActive ? p.theme.colors.black : 'transparent')};
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding: 14px;
  ${(p) => p.theme.max('md')`
    width: 317px;
  `}
`;

const ItemWrap = styled(Flex)`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 60px 8px 40px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  cursor: pointer;
  background: ${(p) =>
    p.isActive ? p.theme.colors.black : p.theme.colors.grays.light};
  /* box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.15); */
  backdrop-filter: blur(20px);
  border-radius: 30px;
  ${(p) =>
    p.isLast &&
    css`
      padding: 35px 8px;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    `}
  ${(p) => p.theme.max('md')`
		margin-bottom: 30px;
	`}
  ${(p) => p.theme.max('sm')`
    margin-left: 0;
		margin-right: 0;
		padding: 20px 8px;
		margin-bottom: 20px;
		${(p) =>
      p.isLast &&
      css`
        padding: 20px 8px;
        flex-direction: column;
        justify-content: center;
      `}
 `}
`;

const ItemText = styled(Text)`
  font-family: TT Norms, Arial, sans-serif;
  text-align: center;
  color: ${(p) => (p.isActive ? p.theme.colors.white : p.theme.colors.black)};
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  width: 270px;
  ${(p) => p.theme.max('sm')`
		font-size: 20px;
		line-height: 26px;
		${(p) =>
      p.isLast &&
      css`
        display: none;
      `}
	`}
`;

const ItemPrice = styled(Text)`
  font-family: TT Norms, Arial, sans-serif;
  text-align: center;
  vertical-align: top;
  color: ${(p) => (p.isActive ? p.theme.colors.white : p.theme.colors.black)};
  padding: 0;
  margin: 0;
  width: 270px;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  ${(p) =>
    p.isLast &&
    css`
      padding-right: 50px;
    `};
  ${(p) =>
    p.isOld &&
    css`
      text-decoration: line-through;
      font-size: 30px;
      line-height: 30px;
      margin-top: 16px;
    `}
  ${(p) => p.theme.max('sm')`
		font-size: ${p.isOld ? 22 : 28}px;
		${(p) =>
      p.isLast &&
      css`
        padding-right: 0;
      `}
	`}
`;

const ItemTextSub = styled(Text)`
  fons-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${(p) => (p.isActive ? p.theme.colors.white : p.theme.colors.black)};
  margin: 0;
  margin-bottom: 16px;
  display: ${(p) => (p.isLast && p.hideFull ? 'none' : 'block')};
  ${(p) => p.theme.max('sm')`
		display: ${(p) => (p.isLast && !p.hideFull ? 'none' : 'block')};
	`};
`;

const ItemAdvantageText = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: ${(p) => (p.isActive ? p.theme.colors.white : p.theme.colors.black)};
  margin: 0;
  margin-bottom: 8px;
  text-align: center;
`;

const ItemTextWrap = styled.div`
  display: flex;
  /* flex: 1; */
  align-items: center;
  p {
    width: auto;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: center;
`;

const PolicyText = styled(Text)`
  color: ${(p) => p.theme.colors.black};
  margin: 0;
  display: inline;
  font-size: 16px;
  margin-right: 4px;
`;

const PolicyLink = styled.a`
  color: ${(p) => p.theme.colors.black};
  text-decoration: none;
  ${(p) => p.theme.max('sm')`
    white-space: break-spaces;
    text-align: center;
	`}
`;

const CheckWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 16px;
`;

const CheckboxCont = styled.div``;

const AdvantagesTextWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default withTranslation()(Subscriptions);
