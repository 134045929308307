import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import LogoutButton from '../components/LogoutButton';
import { Button, SectionWrap, Logo, Text, Box } from '../atoms';
import MaskedAvatar from '../components/MaskedAvatar';
import { withAccount } from 'core/api/accounts/accountContext';
import moment from 'moment';
// import MentalId from 'core/ui/components/landing/MentalId';
// import Surrounding from 'core/ui/components/landing/Surrounding';
// import Analyze from 'core/ui/components/landing/Analyze';
// import Assistent from 'core/ui/components/landing/Assistent';
// import ChatInvent from 'core/ui/components/landing/ChatInvent';
// import Audience from 'core/ui/components/landing/Audience';
// import GradientPromo from 'core/ui/components/landing/GradientPromo';
// import Footer from 'core/ui/components/landing/Footer';
// import PromoBlock from 'core/ui/components/landing/PromoBlock';
import InAppFooter from '../components/InAppFooter';

// import LEFT_DECORATION from '../../assets/images/decoration_1_1.svg';
// import RIGHT_DECORATION from '../../assets/images/decoration_1_2.svg';

const FleroProfile = ({ t, currentUser }) => {
  const avatar =
    currentUser?.personalData?.images && currentUser?.personalData?.images[0];
  const payments = currentUser?.serviceData?.payments;
  const {
    plan,
    expirationDate,
    giftPlan,
    giftPlanUntil,
    expirationDateWeb,
    webPlan,
    recurrentPayment,
    superLikes,
    giftsChat,
    gifts,
  } = payments || {};

  // const PLANS = {
  //   black: 3,
  //   premium: 2,
  //   standard: 1,
  //   free: 0,
  // };

  const actualPlan = () => {
    const now = Date.now();
    const values = [{ plan: 'free', expirationDate: 0, type: 'none' }];
    if (webPlan && expirationDateWeb && expirationDateWeb > now) {
      values.push({ plan: webPlan, expirationDate: expirationDateWeb, type: 'web' });
    }
    if (giftPlan && giftPlanUntil && giftPlanUntil > now) {
      values.push({ plan: giftPlan, expirationDate: giftPlanUntil, type: 'gift' });
    }
    if (plan && expirationDate && expirationDate > now) {
      values.push({ plan, expirationDate: expirationDate, type: 'app' });
    }
    let result = values[0];
    for (const value of values) {
      if (value.expirationDate > result.expirationDate) {
        result = value;
      }
    }
    return result;
  };

  const getPlan = () => {
    const subscription = actualPlan();
    if (subscription.plan === 'standard') {
      return 'plus';
    }
    return subscription.plan;
  };

  // const getExpirationOfSubscription = () => {
  //   const subscription = actualPlan();
  //   return (
  //     <ChargeLine>
  //       <Text>
  //         {recurrentPayment ? t('main.willCharge') : t('main.willNotCharge')}{' '}
  //       </Text>
  //       <Text>
  //         {subscription?.expirationDate
  //           ? moment(subscription.expirationDate).format('DD.MM.YYYY')
  //           : 'Подписка (App Store/Google Play)'}
  //       </Text>
  //     </ChargeLine>
  //   );
  // };

  return (
    <>
      {/* <Decoration isLeft src={LEFT_DECORATION} />
      <Decoration src={RIGHT_DECORATION} /> */}
      <PageWrap>
        <Wrap>
          <Logo />
          <PageHeading>
            {t('main.greetings')}, {currentUser?.personalData?.firstName}!
          </PageHeading>
          <SmallAvatarWrap>
            <MaskedAvatar avatar={avatar} small />
          </SmallAvatarWrap>
          <InfoWrap>
            <PageSubHeading>
              {t('main.fleroProfileYourPlan')}
              <PlanText>: {getPlan()}</PlanText>
            </PageSubHeading>
            {actualPlan()?.expirationDate ? (
              <ChargeLine>
                {recurrentPayment
                  ? t('main.willCharge')
                  : t('main.willNotCharge')}{' '}
                {moment(actualPlan().expirationDate).format('DD.MM.YYYY')}
              </ChargeLine>
            ) : (
              [
                !actualPlan()?.expirationDate &&
                actualPlan().plan !== 'free' ? (
                  <ChargeLine>
                    Подписка оформлена (Google play/App store)
                  </ChargeLine>
                ) : (
                  <ChargeLine>{t('main.willNotCharge')} </ChargeLine>
                ),
              ]
            )}
            <PageSubHeading>
              {t('main.fleroProfileProductsTitle')}
            </PageSubHeading>
            <TableUl>
              <li>
                <TextLine>
                  {t('main.superlikes')}: {superLikes || 0}
                </TextLine>
              </li>
              <li>
                <TextLine>
                  {t('main.giftsFeed')}: {gifts || 0}
                </TextLine>
              </li>
              <li>
                <TextLine>
                  {t('main.giftsChat')}: {giftsChat || 0}
                </TextLine>
              </li>
            </TableUl>
          </InfoWrap>
          <SectionWrap>
            <ButtonContainer>
              <ButtonsWrap>
                <Link to='/subscriptions'>
                  <Button style={{ whiteSpace: 'nowrap' }} onClick={() => null}>
                    {t('main.subscriptionsGoToText')}
                  </Button>
                </Link>
                <Link to='/purchases'>
                  <Button onClick={() => null} variant={'outline'}>
                    {t('main.purchasesGoToText')}
                  </Button>
                </Link>
              </ButtonsWrap>
            </ButtonContainer>
          </SectionWrap>
          <LogoutButton />
        </Wrap>
        <AvatarWrap>
          <MaskedAvatar avatar={avatar} />
        </AvatarWrap>
      </PageWrap>{' '}
      <InAppFooter />
    </>
  );
};

const PageWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  ${(p) => p.theme.max('sm')`
    margin: 0;
  `}
`;

const Wrap = styled.div`
  width: 50%;
  z-index: 99;
  padding: 0 16px;
  ${(p) => p.theme.max('sm')`
    width: 100%;
  `}
`;

const AvatarWrap = styled.div`
  width: 60%;
  position: relative;
  ${(p) => p.theme.max('sm')`
    display: none;
  `}
`;

const SmallAvatarWrap = styled.div`
  display: none;
  ${(p) => p.theme.max('sm')`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 42px 0;
  `}
`;

const PageHeading = styled(Text)`
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  color: ${(p) => p.theme.colors.black};
  text-align: start;
  padding-bottom: 60px;
  ${(p) => p.theme.max('sm')`
    padding-bottom: 20px;
		font-size: 26px;
		line-height: 32px;
		text-align: center;
	`}
`;

const PageSubHeading = styled(Text)`
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: ${(p) => p.theme.colors.black};
  text-align: start;
  margin: 0;
  margin-bottom: 20px;
  ${(p) => p.theme.max('sm')`
		font-size: 24px;
		line-height: 28px;
		text-align: center;
	`}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonsWrap = styled.div`
  justify-content: center;
  display: flex;
  > a:first-child {
    margin-right: 12px;
  }
  ${(p) => p.theme.max('sm')`
		flex-direction: column;
		> a:first-child {
			margin-right: 0;
			margin-bottom: 12px;
		}
	`}
`;

const InfoWrap = styled.div`
  text-align: center;
  margin-bottom: 80px;
`;

const PlanText = styled.span`
  text-transform: uppercase;
`;

const TextLine = styled(Text)`
  font-size: 28px;
  line-height: 24px;
  padding-bottom: 40px;
  font-weight: 500;
  text-align: start;
  white-space: nowrap;
  ${(p) => p.theme.max('sm')`
    padding-bottom: 24px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  `}
`;

const ChargeLine = styled(Box)`
  font-size: 28px;
  line-height: 24px;
  font-weight: 500;
  text-align: start;
  background: ${(p) => p.theme.colors.orange};
  color: ${(p) => p.theme.colors.white};
  border-radius: 10px;
  padding: 12px;
  ${(p) => p.theme.max('md')`
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    padding: 8px;
    margin-bottom: 20px;
  `}
`;

const TableUl = styled.ul`
  ${(p) => p.theme.max('sm')`
      list-style-type: none;
      padding-inline-start: 0px;
    `}
`;

export default withTranslation()(withAccount(FleroProfile));
