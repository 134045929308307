import React from 'react';
import WebContainer from 'core/ui/atoms/WebContainer';
import RULES from './rules';

const RulesPage = () => {
  return (
    <WebContainer>
      <div dangerouslySetInnerHTML={{ __html: RULES }}></div>
    </WebContainer>
  );
};

export default RulesPage;
