import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Container, Text, SectionHeading } from 'core/ui/atoms';
import Success from '../../../assets/images/icons/success';
import Iphone from '../../../assets/images/iphone_6.png';

const Audience = () => {
  const { t } = useTranslation();
  return (
    <CustomContainer>
      <HeaderSeparator>
        <SectionHeading>{t('audience.title')}</SectionHeading>
      </HeaderSeparator>
      <StyledFlexBox>
        <StyledBox>
          <TextContainer>
            <IconContainer>
              <Success />
            </IconContainer>
            <Text primary>{t('audience.text1')}</Text>
          </TextContainer>
          <TextContainer>
            <IconContainer>
              <Success />
            </IconContainer>
            <Text primary>{t('audience.text2')}</Text>
          </TextContainer>
        </StyledBox>
        <Box2>
          <Img src={Iphone} width={327} />
        </Box2>
      </StyledFlexBox>
    </CustomContainer>
  );
};

const CustomContainer = styled(Container)`
  position: relative;
`;
const HeaderSeparator = styled.div`
  padding-bottom: 90px;
  /* width: 800px; */
  ${(p) => p.theme.max('md')`
    /* width: 300px; */
    margin-top: 60px;
  `}
`;

const TextContainer = styled.div`
  width: 476px;
  display: flex;
  ${(p) => p.theme.max('md')`
    width: 300px`}
`;
const IconContainer = styled.div`
  padding-right: 16px;
`;
const StyledBox = styled.div`
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
  padding-bottom: 80px;
  ${(p) => p.theme.max('md')`
    padding-top: 0;
    `}
`;
const StyledFlexBox = styled.div`
  display: grid;
  grid-auto-rows: 50%;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 40px;
  ${(p) => p.theme.max('md')`
    display: block;`}
`;

const Box2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  border-radius: 36px;
  height: 500px;
  position: relative;
  background-color: ${(p) => p.theme.colors.secondaryLanding};
`;
const Img = styled.img``;

export default Audience;
