import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Container, Text, SectionHeading } from '../../atoms';
import Success from '../../../assets/images/icons/success';
import Block from '../../../assets/images/block_img.png';

const Surrounding = () => {
  const { t } = useTranslation();
  return (
    <>
      {' '}
      <Container>
        <HeaderSeparator>
          <SectionHeading>{t('surrounding.title')}</SectionHeading>
        </HeaderSeparator>
        <StyledBox>
          <TextContainer>
            <IconContainer>
              <Success />
            </IconContainer>

            <Text primary>{t('surrounding.text1')}</Text>
          </TextContainer>
          <TextContainer>
            <IconContainer>
              <Success />
            </IconContainer>

            <Text primary>{t('surrounding.text2')}</Text>
          </TextContainer>
        </StyledBox>
      </Container>{' '}
      <ImgContainer>
        <Img src={Block} />
      </ImgContainer>
    </>
  );
};
const HeaderSeparator = styled.div`
  padding-top: 100px;
  padding-bottom: 70px;
  /* width: 800px; */
  ${(p) => p.theme.max('md')`
    padding-top: 100px;
    /* width: 300px; */
  `}
`;

const TextContainer = styled.div`
  width: 583px;
  display: flex;
  ${(p) => p.theme.max('md')`
    width: auto;
    `}
`;
const IconContainer = styled.div`
  padding-right: 16px;
`;
const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 24px;
  padding-bottom: 80px;
  ${(p) => p.theme.max('md')`
    align-items: start;
    padding-bottom: 0px;
    `}
`;

const ImgContainer = styled.div`
  position: relative;
  top: -150px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(p) => p.theme.max('md')`
        top: 0;
    `}
`;
const Img = styled.img`
  width: 100%;
  max-width: 1266px;
  ${(p) => p.theme.max('md')`
    width: auto;
    max-width: 500px;
    overflow: hidden;
`}
`;

export default Surrounding;
