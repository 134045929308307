import React from 'react';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Checkbox, Flex, Section, Text } from '../atoms';
import CHECKBOX_EMPTY from 'core/assets/images/checkbox_empty.svg';
import CHECKBOX_FULL from 'core/assets/images/checkbox_full.svg';

const getDiscountPrice = (price, discount) => {
  if (discount && discount > 0 && discount < 100) {
    return parseInt((price * (100 - discount)) / 100);
  } else {
    return price;
  }
};

const DEFAULT_PRICES = {
  'superlike1': 99,
  'superlike3': 199,
  'superlike5': 299,
  'superlike10': 599,
  'gift.chat': 199,
  'gift.feed': 299
}

const Products = ({
  t,
  // currentUser,
  currentProduct,
  setProduct,
  policyChecked,
  togglePolicy,
  discount
}) => {
  const webDiscount = discount && discount > 0;

  const PURCHASES1 = [
    {
      id: 11,
      title: t('Purchases.superlike1'),
      oldAmount: DEFAULT_PRICES['superlike1'],
      amount: webDiscount
        ? getDiscountPrice(DEFAULT_PRICES['superlike1'], discount)
        : DEFAULT_PRICES['superlike1'],
      product: 'superlike1',
      periodText: t('Purchases.notRecurrent'),
    },
    {
      id: 12,
      title: t('Purchases.superlike3'),
      oldAmount: DEFAULT_PRICES['superlike3'],
      amount: webDiscount
        ? getDiscountPrice(DEFAULT_PRICES['superlike3'], discount)
        : DEFAULT_PRICES['superlike3'],
      product: 'superlike3',
      periodText: t('Purchases.notRecurrent'),
    },
    {
      id: 13,
      title: t('Purchases.superlike5'),
      oldAmount: DEFAULT_PRICES['superlike5'],
      amount: webDiscount
        ? getDiscountPrice(DEFAULT_PRICES['superlike5'], discount)
        : DEFAULT_PRICES['superlike5'],
      product: 'superlike5',
      periodText: t('Purchases.notRecurrent'),
    },
    {
      id: 14,
      title: t('Purchases.superlike10'),
      oldAmount: DEFAULT_PRICES['superlike10'],
      amount: webDiscount
        ? getDiscountPrice(DEFAULT_PRICES['superlike10'], discount)
        : DEFAULT_PRICES['superlike10'],
      product: 'superlike10',
      periodText: t('Purchases.notRecurrent'),
    },
  ];

  // const PURCHASES2 = [
  //   {
  //     id: 15,
  //     title: t('Purchases.giftChat'),
  //     oldAmount: DEFAULT_PRICES['gift.chat'],
  //     amount: webDiscount
  //       ? getDiscountPrice(DEFAULT_PRICES['gift.chat'], discount)
  //       : DEFAULT_PRICES['gift.chat'],
  //     product: 'gift.chat',
  //     periodText: t('Purchases.notRecurrent'),
  //   },
  //   {
  //     id: 16,
  //     title: t('Purchases.giftFeed'),
  //     oldAmount: DEFAULT_PRICES['gift.feed'],
  //     amount: webDiscount
  //       ? getDiscountPrice(DEFAULT_PRICES['gift.feed'], discount)
  //       : DEFAULT_PRICES['gift.feed'],
  //     product: 'gift.feed',
  //     periodText: t('Purchases.notRecurrent'),
  //   },
  // ];

  const onSelect = (t) => {
    setProduct(t);
    setTimeout(() => {
      if (window && window.scrollTo) {
        window.scrollTo({
          left: 0,
          top: document?.body?.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 50);
  };

  return (
    <CustomSection>
      <TiersWrap direction={'column'}>
        <SectionTitle>Суперлайки</SectionTitle>
        <TiersWrap>
          {PURCHASES1.map((t, i) => (
            <ItemWrap
              key={i}
              isActive={currentProduct?.id === t.id}
              onClick={() => onSelect(t)}
            >
              <ItemTextWrap>
                <ItemText>{t.title}</ItemText>
              </ItemTextWrap>
              {webDiscount ? (
                  <ItemPrice isActive={currentProduct?.id === t.id} isOld>
                    {t.oldAmount}.00 ₽
                  </ItemPrice>
                ) : null}
              <ItemPrice>{t.amount}.00 ₽</ItemPrice>
              <ItemTextSub>{t.periodText}</ItemTextSub>
              <CheckWrap>
                {
                  <img
                    src={
                      t.id === currentProduct?.id
                        ? CHECKBOX_FULL
                        : CHECKBOX_EMPTY
                    }
                    alt={'checkbox'}
                  />
                }
              </CheckWrap>
            </ItemWrap>
          ))}
        </TiersWrap>
        {/* <SectionTitle>Генеративные подарки</SectionTitle> */}
        {/* <TiersWrap>
          {PURCHASES2.map((t, i) => (
            <ItemWrap
              key={i}
              isActive={currentProduct?.id === t.id}
              onClick={() => onSelect(t)}
            >
              <ItemTextWrap>
                <ItemText isActive={currentProduct?.id === t.id}>
                  {t.title}
                </ItemText>
              </ItemTextWrap>
              {webDiscount ? (
                  <ItemPrice isActive={currentProduct?.id === t.id} isOld>
                    {t.oldAmount}.00 ₽
                  </ItemPrice>
                ) : null}
              <ItemPrice isActive={currentProduct?.id === t.id}>
                {t.amount}.00 ₽
              </ItemPrice>
              <ItemTextSub isActive={currentProduct?.id === t.id}>
                {t.periodText}
              </ItemTextSub>
              <CheckWrap>
                {
                  <img
                    src={
                      t.id === currentProduct?.id
                        ? CHECKBOX_FULL
                        : CHECKBOX_EMPTY
                    }
                    alt={'checkbox'}
                  />
                }
              </CheckWrap>
            </ItemWrap>
          ))}
        </TiersWrap> */}
      </TiersWrap>
      <BottomContainer>
        <Checkbox checked={policyChecked} onChecked={togglePolicy}>
          <div>
            <PolicyText>Я согласен с</PolicyText>
            <PolicyLink href='/policy' target='_blank'>
              Обработкой персональных данных
            </PolicyLink>
            <PolicyText>{' и '}</PolicyText>
            <PolicyLink href='/eula' target='_blank'>
              Пользовательским соглашением
            </PolicyLink>
          </div>
        </Checkbox>
      </BottomContainer>
    </CustomSection>
  );
};

const CustomSection = styled(Section)`
  padding: 0;
  margin: 0 auto;
  max-width: 1245px;
  ${(p) => p.theme.max('md')`
    margin-bottom: 82px;
 `}
  ${(p) => p.theme.max('sm')`
    margin-bottom: 40px;
 `}
`;

const TiersWrap = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  max-width: 1245px;
  flex-wrap: wrap;
  margin: 0 auto;
  padding-left: -15px;
  padding-right: -15px;
  width: 100%;
  ${(p) => p.theme.max('md')`
        justify-content: center;
     `}
`;

const ItemWrap = styled(Flex)`
  position: relative;
  padding: 24px 8px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border: 1px solid
    ${(p) => (p.isActive ? 'transparent' : p.theme.colors.black)};
  cursor: pointer;
  background: ${(p) => (p.isActive ? p.theme.colors.orange : 'transparent')};
  /* box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.15); */
  backdrop-filter: blur(20px);
  border-radius: 30px;
  margin: 0 15px 30px 15px;
  ${(p) => p.theme.max('md')`
		margin-bottom: 30px;
	`}
  ${(p) => p.theme.max('sm')`
    margin-left: 0;
		margin-right: 0;
		padding: 20px 8px;
		margin-bottom: 20px;
 `}
`;

const ItemText = styled(Text)`
  font-family: TT Norms, Arial, sans-serif;
  text-align: center;
  color: ${(p) => (p.isActive ? p.theme.colors.white : p.theme.colors.black)};
  padding: 0;
  margin: 0;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  width: 270px;
  ${(p) => p.theme.max('sm')`
		font-size: 20px;
		line-height: 24px;
    margin-bottom: 16px;
	`}
`;

const ItemPrice = styled(Text)`
  font-family: TT Norms, Arial, sans-serif;
  text-align: center;
  vertical-align: top;
  color: ${(p) => (p.isActive ? p.theme.colors.white : p.theme.colors.black)};
  padding: 0;
  margin: 0;
  width: 270px;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  ${(p) =>
    p.isLast &&
    css`
      padding-right: 50px;
    `};
  ${(p) =>
    p.isOld &&
    css`
      text-decoration: line-through;
      font-size: 30px;
      line-height: 30px;
      margin-top: 16px;
    `}
  ${(p) => p.theme.max('sm')`
		font-size: ${p.isOld ? 22 : 28}px;
		${(p) =>
      p.isLast &&
      css`
        padding-right: 0;
      `}
	`}
`;

const SectionTitle = styled.h3`
  font-family: TT Norms, Arial, sans-serif;
  text-align: center;
  color: ${(p) => (p.isActive ? p.theme.colors.white : p.theme.colors.black)};
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin: 0;
  margin-bottom: 40px;
  ${(p) => p.theme.max('sm')`
		font-weight: 600;
		font-size: 24px;
		line-height: 28px;
		margin-right: 0;
	`}
`;

const ItemTextSub = styled(Text)`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: ${(p) => (p.isActive ? p.theme.colors.white : p.theme.colors.black)};
  margin: 0;
  display: ${(p) => (p.isLast && p.hideFull ? 'none' : 'block')};
  ${(p) => p.theme.max('sm')`
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
	`};
`;

const ItemTextWrap = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  p {
    width: auto;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
  align-items: center;
`;

const PolicyText = styled(Text)`
  color: ${(p) => p.theme.colors.black};
  margin: 0;
  display: inline;
  font-size: 16px;
  margin-right: 4px;
`;

const PolicyLink = styled.a`
  color: ${(p) => p.theme.colors.black};
  text-decoration: none;
  ${(p) => p.theme.max('sm')`
    white-space: break-spaces;
    text-align: center;
	`}
`;

const CheckWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 16px;
`;

export default withTranslation()(Products);
