import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Head } from 'react-static';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import client from 'core/api/apollo/init';
import { CurrentUserProvider } from '../../api/accounts/accountContext';
import theme from '../theme';
import GlobalStyles from '../GlobalStyles';
import GenericRoute from '../components/GenericRoute';
import NotFound from 'core/ui/pages/NotFound';
import history from 'core/api/history';
import { InAppNotificationProvider } from 'core/api/toast/inAppNotificationsContext';
import Toast from 'core/ui/components/Toast';

import coreRoutes from 'core/api/routes';

const combinedRoutes = [...coreRoutes];

const AppRoot = ({ t }) => {
  return (
    <ApolloProvider client={client}>
      <InAppNotificationProvider>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <CurrentUserProvider>
              <ParallaxProvider>
                {/* <Head>
                  <title>{t('html.title')}</title>
                  <meta
                    property='og:description'
                    content='40-day meditation retreat in your smartphone'
                  />
                  <meta
                    property='twitter:description'
                    content='40-day meditation retreat in your smartphone'
                  />
                  <meta property='og:title' content='Flero' />
                  <meta property='twitter:title' content='Flero' />
                  <meta
                    property='og:image'
                    content='https://dj6wjgdqr93aw.cloudfront.net/og_image.jpg'
                  />
                  <meta
                    property='twitter:image'
                    content='https://dj6wjgdqr93aw.cloudfront.net/og_image.jpg'
                  />
                  <meta property='og:site_name' content='Flero' />
                  <meta property='og:url' content='https://flero.io' />
                  <meta property='twitter:url' content='https://flero.io' />
                </Head> */}
                <Helmet>
                  <meta
                    name='description'
                    content='Российский social-discovery сервис для знакомств со встроенным ИИ-ассистентом на основе совместимости. Позволь искусственному интеллекту найти твою идеальную пару.'
                  />
                </Helmet>
                <div className='App'>
                  <Overflow>
                    <Toast />
                    <Switch>
                      {combinedRoutes.map((routeData) => (
                        <GenericRoute key={routeData.path} {...routeData} />
                      ))}
                      <Route component={NotFound} />
                    </Switch>
                    <GlobalStyles />
                  </Overflow>
                </div>
              </ParallaxProvider>
            </CurrentUserProvider>
          </Router>
        </ThemeProvider>
      </InAppNotificationProvider>
    </ApolloProvider>
  );
};

const Overflow = styled.div`
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export default withTranslation()(AppRoot);
