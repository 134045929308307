import styled, { css } from 'styled-components';
import { hiddenMixin } from 'core/ui/helpers';

const WebContainer = styled.section`
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: calc(${(p) => p.theme.general.containerWidth.lg} - 200px);
  ${(p) =>
    p.relative &&
    css`
      position: relative;
    `}
  ${(p) => p.theme.max('md')`
    width: 100%;
  `}
  ${(p) => p.theme.min('lg')`
    width: calc(${(p) => p.theme.general.containerWidth.lg} - 200px);
  `}
  ${hiddenMixin}
`;

export default WebContainer;
