import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/ru'; // without this line it didn't work

import { Text as TextAtom, Flex, Box } from '../atoms';
import { withTranslation } from 'react-i18next';

const CurrentSubscription = ({ t, currentUser }) => {
  const payments = currentUser?.serviceData?.payments;
  const {
    plan,
    expirationDate,
    giftPlan,
    giftPlanUntil,
    webPlan,
    expirationDateWeb,
    recurrentPayment,
    price,
  } = payments || {};
  const isActive = true;

  const PLANS = {
    black: 3,
    premium: 2,
    standard: 1,
    free: 0,
  };

  const TYPE_ASSOC = {
    app: 'В приложении',
    web: 'Онлайн',
    gift: 'Подарок',
  };

  const PLANS_PRICE = {
    black: 999,
    premium: 599,
    standard: 149,
    free: 0,
  };

  const actualPlan = () => {
    const now = Date.now();
    const values = [{ plan: 'free', expirationDate: 0, type: 'none' }];
    if (webPlan && expirationDateWeb && expirationDateWeb > now) {
      values.push({ plan: webPlan, expirationDate: expirationDateWeb, type: 'web' });
    }
    if (giftPlan && giftPlanUntil && giftPlanUntil > now) {
      values.push({ plan: giftPlan, expirationDate: giftPlanUntil, type: 'gift' });
    }
    if (plan && expirationDate && expirationDate > now) {
      values.push({ plan, expirationDate: expirationDate, type: 'app' });
    }
    let result = values[0];
    for (const value of values) {
      if (value.expirationDate > result.expirationDate) {
        result = value;
      }
    }
    return result;
  };

  // const actualPlan1 = () => {
  //   let giftOrWeb;
  //   if (giftPlan && !webPlan && giftPlanUntil > new Date().getTime()) {
  //     giftOrWeb = {
  //       plan: giftPlan,
  //       expirationDate: giftPlanUntil,
  //       type: 'gift',
  //     };
  //   } else if (
  //     webPlan &&
  //     !giftPlan &&
  //     expirationDateWeb > new Date().getTime()
  //   ) {
  //     giftOrWeb = {
  //       plan: webPlan,
  //       expirationDate: expirationDateWeb,
  //       type: 'web',
  //     };
  //   } else if (webPlan && giftPlan) {
  //     if (
  //       PLANS[giftPlan] > PLANS[webPlan] &&
  //       giftPlanUntil > new Date().getTime()
  //     ) {
  //       giftOrWeb = {
  //         plan: giftPlan,
  //         expirationDate: giftPlanUntil,
  //         type: 'gift',
  //       };
  //     } else if (
  //       PLANS[webPlan] > PLANS[giftPlan] &&
  //       expirationDateWeb > new Date().getTime()
  //     ) {
  //       giftOrWeb = {
  //         plan: webPlan,
  //         expirationDate: expirationDateWeb,
  //         type: 'web',
  //       };
  //     } else {
  //       giftOrWeb = { plan, type: 'app' };
  //     }
  //   }
  //   if (PLANS[giftOrWeb?.plan] < PLANS[plan]) {
  //     giftOrWeb = { plan, type: 'app' };
  //   }
  //   if (!giftOrWeb) {
  //     giftOrWeb = { plan, type: 'app' };
  //   }
  //   return giftOrWeb;
  // };

  const getPlan = () => {
    const sub = actualPlan();
    return sub.plan;
  };

  const currentPlan = getPlan();

  return (
    <Wrapper>
      <Container>
        <Part1>
          <PlanText>{currentPlan === 'standard' ? 'plus': currentPlan}</PlanText>
          <PriceText>{price || PLANS_PRICE[currentPlan]}.00 P</PriceText>
          <PeriodText>{t('Plans.monthlyPayment')}</PeriodText>
          <Box>
            <Text>Тип оплаты: {TYPE_ASSOC[actualPlan().type]}</Text>
          </Box>
        </Part1>
        <Part2>
          <Part3>
            <LabelText>{t('main.status')}</LabelText>
            <StatusText isActive={isActive}>
              {isActive
                ? t('main.subscriptionActive')
                : t('main.subscriptionNotActive')}
            </StatusText>
          </Part3>
          <Part4>
            {actualPlan()?.expirationDate ? (
              <>
                <LabelText>{t('main.expirationDate')}</LabelText>
                <StatusText>
                  {moment(actualPlan().expirationDate).format('DD MMMM YYYY')}
                </StatusText>
                <PeriodText style={{ fontSize: '18px', textAlign: 'center' }}>
                  {recurrentPayment
                    ? 'По окончанию срока действия подписки с вашей карты будут списаны средства за следующий период'
                    : 'Средства не будут списаны'}
                </PeriodText>
              </>
            ) : (
              <>
                <PeriodText>
                  Подписка оформлена
                  <br /> в магазине приложений
                  <br /> ( App Store/Google Play)
                </PeriodText>
              </>
            )}
          </Part4>
        </Part2>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 851px;
  margin: 0 auto;
  padding: 0 16px;
`;

const Container = styled(Flex)`
  background: ${(p) => p.theme.colors.black};
  border-radius: 30px;
  flex-direction: row;
  justify-content: space-between;
  ${(p) => p.theme.max('sm')`
    flex-direction: column;
  `}
`;

const Part1 = styled(Flex)`
  width: 50%;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  margin: auto;
  ${(p) => p.theme.max('sm')`
    width: 100%;
    border-bottom: 1px solid ${(p) => p.theme.colors.white};
  `}
`;

const Part2 = styled(Flex)`
  width: 50%;
  flex-direction: column;
  border-left: 1px solid ${(p) => p.theme.colors.white};
  ${(p) => p.theme.max('sm')`
    width: 100%;
    border-left: none;
    border-bottom: 1px solid ${(p) => p.theme.colors.white};
  `}
`;

const Part3 = styled(Flex)`
  flex-direction: column;
  border-bottom: 1px solid ${(p) => p.theme.colors.white};
  width: 100%;
  align-items: center;
  padding: 24px;
`;

const Part4 = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px;
`;

const Text = styled(TextAtom)`
  color: ${(p) => p.theme.colors.white};
  margin: 0;
`;

const PlanText = styled(Text)`
  font-size: 48px;
  line-height: 58px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
  ${(p) => p.theme.max('sm')`
    font-size: 28px;
    line-height: 34px;
  `}
`;

const PriceText = styled(Text)`
  font-size: 60px;
  line-height: 72px;
  font-weight: 700;
  margin: 0;
  ${(p) => p.theme.max('sm')`
    font-size: 40px;
    line-height: 48px;
  `}
`;

const PeriodText = styled(Text)`
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin-top: 16px;
  ${(p) => p.theme.max('sm')`
    font-size: 18px;
    line-height: 22px;
  `}
`;

const LabelText = styled(Text)`
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 16px;
  ${(p) => p.theme.max('sm')`
    font-size: 18px;
    line-height: 22px;
  `}
`;

const StatusText = styled(Text)`
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  color: ${(p) => (p.isActive ? '#05B646' : p.theme.colors.red)};
  margin: 0;
  ${(p) => p.theme.max('sm')`
    font-size: 24px;
    line-height: 24px;
  `}
`;

export default withTranslation()(CurrentSubscription);
