import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Container, Text, SectionHeading } from 'core/ui/atoms';
import Success from '../../../../assets/images/icons/success';
import Cards from './Cards';

const MentalId = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <HeaderSeparator>
        <SectionHeading>{t('mentalId.title')}</SectionHeading>
      </HeaderSeparator>
      <StyledBox>
        <TextContainer>
          <IconContainer>
            <Success />
          </IconContainer>
          <Text primary>{t('mentalId.text1')}</Text>
        </TextContainer>
        <TextContainer>
          <IconContainer>
            <Success />
          </IconContainer>
          <Text primary>{t('mentalId.text2')}</Text>
        </TextContainer>
      </StyledBox>
      <Cards />
    </Container>
  );
};
const HeaderSeparator = styled.div`
  padding-top: 80px;
  padding-bottom: 70px;
  /* width: 500px; */
  ${(p) => p.theme.max('md')`
    /* padding-top: 40px; */
    /* width: 300px; */
  `}
`;

const TextContainer = styled.div`
  width: 583px;
  display: flex;
  ${(p) => p.theme.max('md')`
    width: auto;
    `}
`;
const IconContainer = styled.div`
  padding-right: 16px;
`;
const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 24px;
  padding-bottom: 80px;
  ${(p) => p.theme.max('md')`
    align-items: start;
    `}
`;

export default MentalId;
