import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

import FLERO_LOGO from '../../../assets/images/flero_logo.svg';
import ARROW_DOWN from '../../../assets/images/arrow_down.png';
import arrowAnimationData from '../../../assets/lottie/signInArrow.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: arrowAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Header = ({ onSignIn }) => {
  const { t } = useTranslation();

  const handleScroll = () => {
    const viewportHeight = window.innerHeight;
    window.scrollBy({
      top: viewportHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Container>
      <SignInButton onClick={onSignIn}>{t('Header.signIn')}</SignInButton>
      <MainWrap>
        <FleroLogo src={FLERO_LOGO} alt={'Flero logo'} />
        <Subtitle>{t('Header.subtitle')}</Subtitle>
      </MainWrap>
      <ArrowDown onClick={handleScroll}>
        {/* <ArrowImg src={ARROW_DOWN} /> */}
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isClickToPauseDisabled={true}
        />
      </ArrowDown>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: relative;
`;

const SignInButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 16px;
  &:hover {
    opacity: 0.8;
  }
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-top: 0;
  font-size: 24px;
  font-weight: 400;
  max-width: 640px;
  padding-left: 24px;
  padding-right: 24px;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ArrowDown = styled.div`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
`;

const MainWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FleroLogo = styled.img`
  width: 400px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    width: 200px;
    margin-bottom: 16px;
  }
`;

const ArrowImg = styled.img`
  width: 100px;
`;

export default Header;
