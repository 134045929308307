import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { withTranslation } from 'react-i18next';

import LogoutButton from '../components/LogoutButton';
import BackButton from '../components/BackButton';
import Products from '../components/Products';
import { Button, SectionWrap, Logo, Text } from '../atoms';
import { withAccount } from 'core/api/accounts/accountContext';
import { INIT_CLOUD_PAYMENT } from 'core/api/apollo/mutations';
import { GET_USER_DISCOUNT } from 'core/api/apollo/queries';

import LEFT_DECORATION from '../../assets/images/decoration_3_1.svg';
import RIGHT_DECORATION from '../../assets/images/decoration_3_2.svg';
import InAppFooter from '../components/InAppFooter';

const FleroPurchases = ({ t, currentUser, refetchCurrentUser }) => {
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(false);
  const [policyChecked, setPolicyChecked] = useState(false);

  const [initCloudPayment] = useMutation(INIT_CLOUD_PAYMENT);

  const {
    data: discountData,
    loading: discountLoading,
    error: discountError,
  } = useQuery(GET_USER_DISCOUNT);

  // const discountType =
  //   (discountData &&
  //     discountData.getUserDiscount &&
  //     discountData.getUserDiscount.type) ||
  //   'none';
  // const isFemale =
  //   currentUser.personalData.gender === 'FEMALE' || discountType === 'march8';
  // let discount = 0;
  // if (isFemale) {
  //   discount =
  //     (discountData &&
  //       discountData.getUserDiscount &&
  //       discountData.getUserDiscount.discount) ||
  //     0;
  // }
  const discount = 0;

  const togglePolicy = () => {
    setPolicyChecked((st) => !st);
  };

  const purchase = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const res = await initCloudPayment({
        variables: {
          input: {
            product: product.product,
            amount: product.amount,
            productName: product.title,
            isSubscription: false,
          },
        },
      });
      const orderId = res.data.initCloudPayment.orderId;
      const widget = new window.cp.CloudPayments({
        language: 'ru-RU',
      });
      widget.pay(
        'charge', // или 'auth'
        {
          publicId: 'pk_1eb71f6c518517b55f5d96c94af11',
          description: `Оплата товаров в flero.io. ${product.title}.`,
          amount: product.amount,
          currency: 'RUB',
          accountId: currentUser?._id,
          email: currentUser?.personalData?.email,
          data: {
            product: product.product,
            amount: product.amount,
            productName: product.title,
            isSubscription: false,
            orderId,
          },
          invoiceId: orderId,
          autoClose: 3,
          // skin: 'mini',
        },
        {
          onSuccess: function (options) {
            // действие при успешной оплате
            console.log('Success', options);
            setLoading(false);
            refetchCurrentUser();
          },
          onFail: function (reason, options) {
            // действие при неуспешной оплате
            console.log('Fail', reason, options);
            setLoading(false);
          },
          onComplete: function (paymentResult, options) {
            // Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
            // например вызов вашей аналитики Facebook Pixel
            console.log('Complete', paymentResult, options);
            setLoading(false);
            refetchCurrentUser();
          },
        }
      );
    } catch (e) {
      // console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Decoration isLeft src={LEFT_DECORATION} />
      <Decoration src={RIGHT_DECORATION} />
      <Wrap>
        <Logo />
        <PageHeading>{t('products.pageTitle')}</PageHeading>
        <Products
          currentProduct={product}
          setProduct={setProduct}
          policyChecked={policyChecked}
          togglePolicy={togglePolicy}
          discount={discount}
        />
        <SectionWrap>
          <ButtonContainer>
            <ButtonsWrap>
              <Button
                onClick={purchase}
                isLoading={loading}
                disabled={!product || !policyChecked}
              >
                {t('main.proceed')}
              </Button>
            </ButtonsWrap>
            <PurchaseButtonDisclaimer>
              {t('main.purchaseButtonDisclaimer')}
            </PurchaseButtonDisclaimer>
          </ButtonContainer>
        </SectionWrap>
        <BackButton />
        <LogoutButton />
      </Wrap>
      <InAppFooter />
    </>
  );
};

const PageHeading = styled(Text)`
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  color: ${(p) => p.theme.colors.black};
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
  ${(p) => p.theme.max('sm')`
		font-size: 26px;
		line-height: 32px;
		text-align: center;
	`}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ButtonsWrap = styled.div`
  justify-content: center;
  display: flex;
  > button:first-child {
    margin-right: 12px;
  }
  ${(p) => p.theme.max('sm')`
		flex-direction: column;
		> button:first-child {
			margin-right: 0;
			margin-bottom: 12px;
		}
	`}
`;

const PurchaseButtonDisclaimer = styled(Text)`
  color: ${(p) => p.theme.colors.grays.regular};
  text-align: center;
`;

const Decoration = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  ${(p) =>
    p.isLeft &&
    css`
      top: auto;
      right: auto;
      left: 0;
      bottom: 0;
    `}
  ${(p) => p.theme.max('sm')`
    width: 60%;
  `}
`;

const Wrap = styled.div`
  z-index: 99;
  padding: 0 16px;
`;

export default withTranslation()(withAccount(FleroPurchases));
