import { gql } from '@apollo/client';

import { promocodeData } from 'core/api/apollo/fragments';

export const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!, $isAdmin: Boolean) {
    signIn(email: $email, password: $password, isAdmin: $isAdmin) {
      token
    }
  }
`;

export const WEB_SIGN_IN_WITH_APPLE = gql`
  mutation webSignInWithApple($token: String!, $userId: String!) {
    webSignInWithApple(token: $token, userId: $userId) {
      token
    }
  }
`;

export const WEB_SIGN_IN_WITH_GOOGLE = gql`
  mutation webSignInWithGoogle($token: String!, $userId: String!) {
    webSignInWithGoogle(token: $token, userId: $userId) {
      token
    }
  }
`;

export const SIGN_IN_WITH_TOKEN = gql`
  mutation signInWithToken($token: String!) {
    signInWithToken(token: $token) {
      token
    }
  }
`;

export const INIT_SUBSCRIPTION = gql`
  mutation InitSubscription($input: InitSubInput!) {
    initSubscription(input: $input) {
      status
      paymentUrl
    }
  }
`;

export const INIT_SUBSCRIPTION_SBP = gql`
  mutation InitSubscriptionSBP($input: InitSubInput!) {
    initSubscriptionSBP(input: $input) {
      status
      paymentUrl
    }
  }
`;

export const INIT_PAYMENT = gql`
  mutation InitPayment($input: InitPaymentInput!) {
    initPayment(input: $input) {
      status
      paymentUrl
    }
  }
`;

export const INIT_PAYMENT_SBP = gql`
  mutation InitPaymentSBP($input: InitSubInput!) {
    initPaymentSBP(input: $input) {
      status
      paymentUrl
    }
  }
`;

export const INIT_CLOUD_PAYMENT = gql`
  mutation InitCloudPayment($input: InitCloudPaymentInput!) {
    initCloudPayment(input: $input) {
      status
      orderId
    }
  }
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    cancelSubscription
  }
`;

export const CANCEL_CLOUD_SUBSCRIPTION = gql`
  mutation CancelCloudSubscription {
    cancelCloudSubscription
  }
`;

export const CLEAR_SUBSCRIPTION = gql`
  mutation ClearSubscription {
    clearSubscription
  }
`;

export const ACTIVATE_PROMOCODE = gql`
  mutation ActivatePromocode($code: String!) {
    activatePromocode(code: $code) {
      status
      promocode {
        ...PromocodeData
      }
    }
  }
  ${promocodeData}
`;